import React, { Component } from "react";
import { Modal, Responsive, Button, Icon } from "semantic-ui-react";

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.renderQuitButton = this.renderQuitButton.bind(this);
  }

  renderQuitButton() {
    if (this.props.dontShowQuit === true) {
      return <div />;
    } else {
      return (
        <div
          style={{
            zIndex: 100,
            width: "100%",
            height: "5%",
            marginTop: "0.5rem",
            top: 0
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "flex-end",
              alignSelf: "flex-end",
              justifyContent: "flex-end"
            }}
          >
            <Button
              arial-label={"Cerrar Modal"}
              onClick={() => {
                this.props.closeModal();
              }}
              style={{
                padding: 0,
                margin: 0,
                backgroundColor: "transparent",
                marginTop: "0.8rem",
                marginRight: "1rem",
                width: "5rem",
                height: "100%",
                display: "flex",
                textAlign: "right",
                alignItems: "flex-end",
                alignSelf: "flex-end",
                justifyContent: "flex-end"
              }}
            >
              <Icon
                name={"close"}
                style={{
                  textAlign: "right",
                  fontSize:
                    this.props.closeSize !== undefined
                      ? this.props.closeSize
                      : this.props.setBasic !== true
                      ? "1rem"
                      : "1.4rem",
                  color:
                    this.props.closeColor !== undefined
                      ? this.props.closeColor
                      : this.props.setBasic !== true
                      ? "black"
                      : "white"
                }}
              />
            </Button>
          </div>
        </div>
      );
    }
  }

  render() {
    let addHeight = {};
    let styleInLine = {
      ...addHeight,
      ...addBackgroundColor
    }

    if (this.props.height !== undefined) {
      addHeight = { height: this.props.height };
    }

    if(!this.props.className){
      styleInLine.width = (this.props.width)? this.props.width : "50%";
    }

    let addBackgroundColor = {};
    if (this.props.backgroundColor !== undefined) {
      addBackgroundColor = { backgroundColor: this.props.backgroundColor };
    }
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          <Modal
            basic={this.props.setBasic === true ? true : false}
            onMount={() => {
              if (this.props.onOpen !== undefined) {
                this.props.onOpen();
              }
            }}
            onClose={(event, data) => {
              this.props.closeModal();
            }}
            open={this.props.showModal || false}
            style={{ width: "98%", ...addHeight, ...addBackgroundColor }}
          >
            {this.renderQuitButton()}
            {this.props.children}
            {this.props.dontShowQuit !== true && (
              <div style={{ width: "100%", height: "5%" }} />
            )}
          </Modal>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Modal
            className={(this.props.className)?this.props.className : ''}
            basic={this.props.setBasic === true ? true : false}
            onMount={() => {
              if (this.props.onOpen !== undefined) {
                this.props.onOpen();
              }
            }}
            onClose={(event, data) => {
              this.props.closeModal();
            }}
            open={this.props.showModal || false}
            style={styleInLine}
          >
            {this.renderQuitButton()}
            <div style={{ width: "100%", height: "90%" }}>
              {this.props.children}
            </div>
            {this.props.dontShowQuit !== true && (
              <div style={{ width: "100%", height: "1.4rem" }} />
            )}
          </Modal>
        </Responsive>
      </div>
    );
  }
}

export default ModalComponent;
