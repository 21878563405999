import React, { Component } from "react";

export default class LoadingComponent extends Component {
  render() {
    if (this.props.isLoading) {
      return <div>Loading...</div>;
    }
    // Handle the error state
    else if (this.props.error) {
      return <div>Sorry, there was a problem loading the page.</div>;
    } else {
      return null;
    }
  }
}
