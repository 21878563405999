import React, { Component } from "react";
import { Grid, Responsive, Segment } from "semantic-ui-react";
export default class GridForContent extends Component {
  render() {
    return (
      <Segment
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "transparent",
          border: "0px",
          margin: 0,
          padding: 0,
          elevation: 0,
          borderRadius: 0,
          borderColor: 0,
          borderBottomColor: "transparent",
          borderBottomWidth: 0,
          boxShadow: "0px 0px 0px",
          ...this.props.style,
          padding: 0
        }}
      >
        <Responsive
          {...Responsive.onlyMobile}
          style={{ width: "100%", height: "100%" }}
        >
          <Grid
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              margin: 0,
              padding: 0
            }}
            columns="equal"
          >
            <Grid.Column
              style={{
                flexDirection: "column",
                ...this.props.contentStyle
              }}
              align={this.props.align || "left"}
              mobile={15}
              tablet={this.props.viewWidth || 12}
              computer={this.props.viewWidth || 12}
            >
              {this.props.children}
            </Grid.Column>
          </Grid>
        </Responsive>
        <Responsive
          minWidth={Responsive.onlyTablet.minWidth}
          style={{ width: "100%", height: "100%" }}
        >
          <Grid
            style={{
              width: "100%",
              height: "100%",
              margin: 0,
              padding: 0
            }}
            columns="equal"
          >
            <Grid.Column only="computer tablet" />
            <Grid.Column
              style={{
                flexDirection: "column",
                margin: 0,
                padding: 0,
                ...this.props.contentStyle
              }}
              align={this.props.align || "left"}
              mobile={16}
              tablet={this.props.viewWidth || 14}
              computer={this.props.viewWidth || 14}
            >
              {this.props.children}
            </Grid.Column>
            <Grid.Column only="computer tablet" />
          </Grid>
        </Responsive>
      </Segment>
    );
  }
}
