import React, { Component } from "react";
import PropTypes from 'prop-types'
import validator from 'validator';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
    Form,
  Button,
  TextArea,
  Grid,
  Input,
  Modal,
} from "semantic-ui-react";
import InputMask from "react-input-mask";
import pick from "lodash/pick";
import { requestSendJob } from "../redux/actions/DataActions";
import ModalComponent from "../layouts/ModalComponent";
import { GetContent, GetPage, GetTextFromContent } from "../scripts";

import FormValidator from "../utils/formValidator";
import getPlatform from "../helpers/getPlatform";
import ModalSendEmail from "./ModalSendEmail";


import "../style/globalstyle.css";


class JobbankModal extends Component {
  
  static propTypes = {
    header: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string
    }),
    messageConfirm: PropTypes.string,
    onClose: PropTypes.func,
    statusModal: PropTypes.bool.isRequired,

  }

  static defaultProps = {
    header: {
      title: '',
      description: ''
    },
    messageConfirm: '',
    statusModal: false
  }

  rules = [
    { 
      field: 'name',
      method: validator.matches,
      args: [/a-zA-Z\sŽšžŸÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðñòóôõöùúûüýÿ]+/ig],
      validWhen: false,
      message: 'El nombre debe ser solo letras.'
    },
    { 
      field: 'name',
      method: validator.isEmpty,
      validWhen: false,
      message: 'El nombre es requerido.'
    },
    { 
      field: 'name',
      method: validator.isLength,
      args: [{min: 3, max: 70}],
      validWhen: true,
      message: 'El nombre debe de contener entre 3 y 70  caracteres'
    },
    {
      field: 'email',
      method: validator.isEmpty,
      validWhen: false,
      message: 'El correo es requerido.'
    },
    { 
      field: 'email',
      method: validator.isEmail,
      validWhen: true,
      message: 'Ingresa un email valido.'
    },
    { 
      field: 'phoneNumber',
      method: validator.isEmpty,
      validWhen: false,
      message: 'El número telefónico es requerido.'
    },
    { 
      field: 'phoneNumber',
      method: validator.isMobilePhone,
      args: ['es-MX'],
      validWhen: false,
      message: 'El número telefónico es requerido.'
    },
    { 
      field: 'message',
      method: validator.isEmpty,
      validWhen: false,
      message: 'El mensaje es requerido.'
    },
    { 
      field: 'message',
      method: validator.isLength,
      args: [{min: 10, max: 300}],
      validWhen: true,
      message: 'El mensaje debe de contener entre 10 y 300  caracteres'
    },
    { 
      field: 'termAndCond',
      method: validator.equals,
      args: ['false'],
      validWhen: false,
      message: 'Los términos y condiciones son requeridos'
    }
  ];

  validator = {}
  validation = {}

  defaultValues =  {
    value: {
      name: '',
      message: '',
      email: '',
      phoneNumber: '',
      termAndCond: 'false'
    },
    dirty: {
      name: false,
      message: false,
      email: false,
      phoneNumber: false,
      termAndCond: false
    }
  }

  defaultState = {
    form: this.defaultValues,
    validation:{},
    showEmailModal: false
  }

  constructor(props){
    super(props);

    this.state = this.defaultState;

    this.validator = new FormValidator(this.rules);
    
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePhoneNumber = this.handleChangePhoneNumber.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleChangeTermAndCon = this.handleChangeTermAndCon.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeName(e, data){
    this.setState({form: { value: {...this.state.form.value, name: data.value}, dirty: {...this.state.form.dirty, name: true}}});
  }

  handleChangeEmail(e, data){
    this.setState({form: { value: {...this.state.form.value, email: data.value}, dirty: {...this.state.form.dirty, email: true}}});
  }

  handleChangePhoneNumber(e){
    this.setState({form: { value: {...this.state.form.value, phoneNumber: e.target.value}, dirty: {...this.state.form.dirty, phoneNumber: true}}});
  }

  handleChangeMessage(e, data){
    this.setState({form: { value: {...this.state.form.value, message: data.value}, dirty: {...this.state.form.dirty, message: true}}});
  }
  
  handleChangeTermAndCon(e, data){
    this.setState({form: { value: {...this.state.form.value, termAndCond: data.checked.toString()}, dirty: {...this.state.form.dirty, termAndCond: true}}});
  }
  async handleSubmit(){
    this.validation = this.validator.validate(this.state.form.value);
    
    if(!this.validation.isValid){
      this.setState({
            validation: this.validation, 
            form:{
                ...this.state.form
                ,dirty: {
                name: true,
                message: true,
                email: true,
                phoneNumber: true,
                termAndCond: true
              }
            },
            showEmailModal: false
      });
      return;
    }

    const dataPlatform = await getPlatform();
    const response = await this.props.requestSendJob({...pick(this.state.form.value, ['name', 'message', 'email', 'phoneNumber', 'termAndCond']), ...dataPlatform});
    
    if(response === 'ok'){
      this.setState({form: this.defaultValues, showEmailModal: !this.state.showEmailModal});
    }

  }
  
  handleClickClose(e){
    
    let onClose = this.props.onClose;
    this.setState(this.defaultState);
    if(onClose) onClose(e);
  }
 
 
  render() {
    //this.validation = this.validator.validate(this.state.form.value);
    const validationExist = (Object.keys(this.state.validation).length)? true : false,
    { validation, form } = this.state;
    const { statusModal } = this.props;
    const validName = validationExist && validation.name.isInvalid && form.dirty.name,
        validEmail = validationExist && validation.email.isInvalid && form.dirty.email,
        validPhoneNumber = validationExist &&validation.phoneNumber.isInvalid && form.dirty.phoneNumber,
        validMessage = validationExist && validation.message.isInvalid && form.dirty.message,
        validTermAndCon = validationExist && validation.termAndCond.isInvalid && form.dirty.termAndCond
    
    return (
      <ModalComponent
        className="jobbankmodal_modal"
        closeSize={"1.3rem"}
        setBasic={false}
        closeModal={this.handleClickClose}
        backgroundColor={"#DDDEE0"}
        showModal={statusModal}
      >
        <Modal.Header className="jobbankmodal__container">
          <h2 className="jobbankmodal__title">{this.props.header.title}</h2>
        </Modal.Header>
        <Modal.Content className="jobbankmodal__container jobbankmodal__description-section">
          <h3 className="jobbankmodal__description">{this.props.header.description}</h3>
        </Modal.Content>
        <Modal.Content className="jobbankmodal__container">
            <Form>
              <Grid>
                  <Grid.Row>
                      <Grid.Column className={(validName)? 'form__input-error': ''}>
                          <Input size='big' fluid 
                          error={validName}
                          placeholder='Nombre'
                          value={form.name}
                          onChange={this.handleChangeName}/>
                          {
                            (validName) && (
                              <span>{validation.name.message}</span>
                            )
                          }
                      </Grid.Column>
                  </Grid.Row>
                   <Grid.Row>
                    <Grid.Column className={(validEmail)? 'form__input-error': ''}>
                        <Input size='big' fluid 
                        error={validEmail}
                        placeholder='Correo electrónico' 
                        value={form.email}
                        onChange={this.handleChangeEmail}/>  
                        {
                            (validEmail) && (
                              <span>{this.validation.email.message}</span>
                            )
                          }
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className={(validPhoneNumber)? 'form__input-error': ''}>
                      <Input size='big' fluid placeholder='Número Telefónico' 
                      error={validPhoneNumber}>
                        <InputMask
                          placeholder="(55) 5555-5555"
                          mask="(99) 9999-9999"
                          value={form.phoneNumber}
                          onChange={this.handleChangePhoneNumber}
                        />
                      </Input>
                      {
                            (validPhoneNumber) && (
                              <span>{this.validation.phoneNumber.message}</span>
                            )
                          }
                    </Grid.Column>
                  </Grid.Row>
                 <Grid.Row>
                    <Grid.Column className={(validMessage)? 'form__input-error': ''}>
                      <TextArea className="jobbankmodal__textarea" 
                      error={validMessage}
                          placeholder='Mensaje'
                          value={form.message}
                          onChange={this.handleChangeMessage}/>
                      {
                            (validMessage) && (
                              <span>{this.validation.message.message}</span>
                            )
                          }
                    </Grid.Column>
                  </Grid.Row>
                   <Grid.Row>
                    <Grid.Column>
                    <Form.Checkbox
                    error={validTermAndCon}
                    onChange={this.handleChangeTermAndCon}
                    label={
                    <label>
                        Estoy de acuerdo con los &nbsp;&nbsp;
                          <a href="terminos-y-condiciones-bolsa-de-trabajo">
                            Términos
                          </a>&nbsp;&nbsp;y&nbsp;&nbsp; 
                          <a href="politica-de-privacidad-bolsa-de-trabajo">
                            Política de Privacidad
                          </a>&nbsp;&nbsp;
                          de Bolsa de trabajo. <t style={{ color: "red" }}>*</t>    
                    </label>
                  } />
                    </Grid.Column> 
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                        <Button fluid 
                        disabled={this.state.validation.isValid}
                        className="jobbankmodal__button"
                        onClick={this.handleSubmit}>
                            ENVIAR
                        </Button>
                    </Grid.Column>
                  </Grid.Row>
              </Grid>
              </Form>
        </Modal.Content>
        <ModalSendEmail
          message={this.props.messageConfirm}
          showModal={this.state.showEmailModal}
          closeModal={this.handleClickClose}
          name={this.state.form.value.name}
          email={this.state.form.value.email}
          phone={this.state.form.value.phoneNumber}
        />
      </ModalComponent>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { idcheck, pages } = data;
  return {
    idcheck,
    pages
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {requestSendJob}
  )(JobbankModal)
);
