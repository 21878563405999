import * as types from "../types";

const INITIAL_STATE = {
  pages: [],
  api: {},
  idcheck: {},
  amenities: [],
  states: [],
  statesURLs: [],
  concepts: [],
  conceptsURLs: [],
  developments: [],
  developmentsURLs: [],
  homemodels: [],
  homemodelsURLs: [],
  credits: [],
  creditsURLs: [],
  creditTypes: [],
  creditTypesURLs: [],
  blogs: [],
  blogFeatured: [],
  blogsURLs: [],
  promotions: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_PAGES: {
      return { ...state, pages: action.pages };
    }
    case types.SET_API: {
      return { ...state, api: action.api };
    }
    case types.SET_ID_CHECK: {
      return { ...state, idcheck: action.idcheck };
    }
    case types.SET_AMENITIES: {
      return { ...state, amenities: action.amenities };
    }
    case types.SET_STATES: {
      return { ...state, states: action.states };
    }
    case types.SET_STATES_URLS: {
      return { ...state, statesURLs: action.statesURLs };
    }
    case types.SET_CONCEPTS: {
      return { ...state, concepts: action.concepts };
    }
    case types.SET_CONCEPTS_URLS: {
      return { ...state, conceptsURLs: action.conceptsURLs };
    }
    case types.SET_DEVELOPMENTS: {
      return { ...state, developments: action.developments };
    }
    case types.SET_DEVELOPMENTS_URLS: {
      return { ...state, developmentsURLs: action.developmentsURLs };
    }
    case types.SET_HOMEMODELS: {
      return { ...state, homemodels: action.homemodels };
    }
    case types.SET_HOMEMODELS_URLS: {
      return { ...state, homemodelsURLs: action.homemodelsURLs };
    }
    case types.SET_CREDITS: {
      return { ...state, credits: action.credits };
    }
    case types.SET_CREDITS_URLS: {
      return { ...state, creditsURLs: action.creditsURLs };
    }
    case types.SET_CREDIT_TYPES: {
      return { ...state, creditTypes: action.creditTypes };
    }
    case types.SET_CREDIT_TYPES_URLS: {
      return { ...state, creditTypesURLs: action.creditTypesURLs };
    }
    case types.SET_BLOGS: {
      return { ...state, blogs: action.blogs };
    }
    case types.SET_BLOG_FEATURED: {
      return { ...state, blogFeatured: action.blogFeatured };
    }
    case types.SET_BLOG_URLS: {
      return { ...state, blogsURLs: action.blogsURLs };
    }
    case types.SET_PROMOTIONS: {
      return { ...state, promotions: action.promotions };
    }
    default: {
      return state;
    }
  }
};
