
//The base language of the page/system (check the proyect for that)
export const baseLanguage = {
    key: "es",
    value: "es",
    data: "",
    text: "Español"
  };

export const getCurrentLanguage = language => {
    if (language === baseLanguage.key) {
      return "";
    } else {
      return "_" + language;
    }
  };
