import * as types from '../types';

const stateDefault ={
  blogInfo: [],
  postsFeatured: [],
  posts: {
    pages: 1,
    pages: 1,
    posts: 0,
    totalPosts: 0
  },
  lastPost: {},
  moreVisited: [],
  blogCategories: [],
  blogTags: []
};

export default function reducer(state = stateDefault, action){
  const { type, payload } = action; 
  
  switch( type ) {
    case types.SAVE_BLOG_INFO: 
      return { ...state,  blogInfo: payload.data};
    case types.SAVE_POST_FEATURED: 
      return { ...state,  postsFeatured: payload.data};
    case types.SAVE_POSTS_LIST: 
      return { ...state,  posts: payload.data};
    case types.SAVE_LAST_POST: 
      return { ...state,  lastPost: payload.data};
    case types.SAVE_BLOG_CATEGORIES: 
      return { ...state,  blogCategories: payload.data};
    case types.SAVE_BLOG_TAGS: 
      return { ...state,  blogTags: payload.data};
    case types.SAVE_POSTS_MORE_VISITED: 
      return { ...state,  moreVisited: payload.data};
    default:
      return state;
  }
}

