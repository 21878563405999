import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import {
  Button,
  Container,
  Divider,
  Grid,
  List,
  Responsive,
  Icon
} from "semantic-ui-react";
import API from "../API/API";
import {
  imgfooter1,
  imgfooter3,
  imgfooter4,
  logowhite
} from "../assets/Assets";
import {
  GetContent,
  GetLinkFromContent,
  GetPage,
  GetTextFromContent
} from "../scripts";

import ModalContact from "../components/ModalContact";
import JobbankModal from "../components/JobbankModal";

import orderArray from "../helpers/orderArray";
import { styleBasic } from "../style/inlineStyles";

class Footer extends Component {
  conceptsURLs = [];

  constructor(props) {
    super(props);
    this.renderBaseTopHeaderColumn = this.renderBaseTopHeaderColumn.bind(this);
    this.verifyPages = this.verifyPages.bind(this);
    //this.contactInfo = {};
    //this.linkInfo = {};

    this.state = {
      contactInfo: {},
      linkInfo: {},
      jobBankInfo: {},
      showContactModal: false,
      showJobBankModal: false
    };

    this.orderConcepts = this.orderConcepts.bind(this);
    this.handleClickContactModel = this.handleClickContactModel.bind(this);
    this.handleClickJobBankModel = this.handleClickJobBankModel.bind(this);
  }

  componentDidMount() {
    this.orderConcepts(this.props.conceptsURLs);
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.orderConcepts(nextProps.conceptsURLs);
    this.verifyPages(nextProps.pages);
  }

  orderConcepts(conceptsURLs) {
    if (
      conceptsURLs &&
      Array.isArray(conceptsURLs) &&
      conceptsURLs.length > 0
    ) {
      this.conceptsURLs = orderArray(conceptsURLs, "order");
    }
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "internal").id;

      api.getContentOf(pageId).then(response => {
        const contactInfo = GetContent(response[0], "contact", "main"),
              linkInfo = GetContent(response[0], "main", "links"),
              jobBankData = GetContent(response[0], "main", "jobBank");

        const jobBankInfo = {
          header: {
            title: GetTextFromContent(jobBankData, "title"),
            description: GetTextFromContent(jobBankData, "description")
          },
          messageConfirm: GetTextFromContent(jobBankData, "messageConfirm"),
        }
        this.setState({contactInfo, linkInfo, jobBankInfo})
      });
    }
  }

  handleClickContactModel() {
    this.setState({ showContactModal: !this.state.showContactModal });
  }

  handleClickJobBankModel(){
    this.setState({ showJobBankModal: !this.state.showJobBankModal });
  }

  renderBaseTopHeaderColumn(link, img) {
    return (
      <Grid.Column
        href={GetLinkFromContent(this.state.linkInfo, link)}
        target={"_blank"}
        width={4}
        align="center"
        verticalAlign="middle"
      >
        <Responsive {...Responsive.onlyMobile}>
          <div style={{ width: "100%", height: "3.6rem", margin: "0rem" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: "url(" + img + ")",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat"
              }}
            />
          </div>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <div style={{ width: "100%", height: "4.7rem", margin: "0rem 2rem" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: "url(" + img + ")",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat"
              }}
            />
          </div>
        </Responsive>
      </Grid.Column>
    );
  }

  mainRender() {
    return (
      <div style={{ marginTop: "2rem" }}>
        {this.props.extraFooter ? (
          <div style={{ backgroundColor: "#efefef", padding: "0.6rem 0rem" }}>
            <Container>
              <Grid
                stackable
                centered
                columns={16}
                style={{ ...styleBasic, width: "100%" }}
              >
                {this.renderBaseTopHeaderColumn("araQuality", imgfooter1)}
                {this.renderBaseTopHeaderColumn("consortium", imgfooter3)}
                {this.renderBaseTopHeaderColumn("greatPlace", imgfooter4)}
              </Grid>
            </Container>
          </div>
        ) : (
          <div />
        )}
        <div style={{ backgroundColor: "#313131", padding: "2rem 0rem" }}>
          <Container>
            <Grid columns={"equal"} style={{ ...styleBasic, width: "100%" }}>
              <Grid.Column width={1} only={"mobile"} />
              <Grid.Column style={{ ...styleBasic }}>
                <Grid
                  style={{ ...styleBasic, width: "100%" }}
                  stackable
                  columns={"equal"}
                >
                  <Grid.Column only={"computer"}>
                    <div
                      onClick={() => {
                        this.props.push("/");
                        window.scroll({
                          top: document.getElementById("nav").offsetTop,
                          behavior: "auto"
                        });
                      }}
                      style={{
                        cursor: "pointer",
                        backgroundImage: "url(" + logowhite + ")",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        height: "10rem",
                        width: "10rem"
                      }}
                    />
                    <Grid padded="horizontally" relaxed={2}>
                      <Button
                        arial-label={"Link a Facebook"}
                        href={GetLinkFromContent(this.state.linkInfo, "facebook")}
                        target={"_blank"}
                        circular
                        color="facebook"
                        icon="facebook"
                      />
                      <Button
                        arial-label={"Link a Twitter"}
                        href={GetLinkFromContent(this.state.linkInfo, "twitter")}
                        target={"_blank"}
                        circular
                        color="twitter"
                        icon="twitter"
                      />
                      <Button
                        arial-label={"Link a YouTube"}
                        href={GetLinkFromContent(this.state.linkInfo, "youtube")}
                        target={"_blank"}
                        circular
                        color="youtube"
                        icon="youtube"
                      />
                    </Grid>
                  </Grid.Column>
                  <Grid.Column>
                    <h3
                      style={{
                        textTransform: "uppercase",
                        color: "white",
                        fontWeight: "bold"
                      }}
                    >
                      Consorcio ARA
                    </h3>
                    <List>
                      <List.Item
                        onClick={() => {
                          this.props.push("/acerca-de");
                          window.scroll({
                            top: document.getElementById("nav").offsetTop,
                            behavior: "auto"
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          color: "#939393"
                        }}
                      >
                        {"Acerca de ARA"}
                      </List.Item>
                      <List.Item
                        style={{
                          color: "#939393"
                        }}
                        href={GetLinkFromContent(this.state.linkInfo, "consortium")}
                        target={"_blank"}
                      >
                        {"Relación con Inversionistas"}
                      </List.Item>
                      <List.Item
                        onClick={this.handleClickJobBankModel}
                        style={{
                          cursor: "pointer",
                          color: "#939393"
                        }}>
                        Bolsa de trabajo
                      </List.Item>
                    </List>
                  </Grid.Column>
                  <Grid.Column>
                    <h3
                      style={{
                        textTransform: "uppercase",
                        color: "white",
                        fontWeight: "bold"
                      }}
                    >
                      Tipo de casas
                    </h3>
                    <List>
                      {this.conceptsURLs.map(item => {
                        return (
                          <List.Item
                            onClick={() => {
                              this.props.push("/" + item.url.trim());
                              window.scroll({
                                top: document.getElementById("nav").offsetTop,
                                behavior: "auto"
                              });
                            }}
                            style={{
                              cursor: "pointer",
                              color: "#939393"
                            }}
                          >
                            {item.name}
                          </List.Item>
                        );
                      })}
                    </List>
                  </Grid.Column>
                  <Grid.Column>
                    <h3
                      style={{
                        textTransform: "uppercase",
                        color: "white",
                        fontWeight: "bold"
                      }}
                    >
                      Contacto
                    </h3>
                    <List>
                      <List.Item
                        style={{
                          color: "#939393"
                        }}
                      >
                        {"Llámanos gratis:"}
                      </List.Item>
                      <List.Item
                        style={{
                          color: "#c43a0e"
                        }}
                        href={
                          "tel:" +
                          GetTextFromContent(this.state.contactInfo, "phoneSales")
                        }
                      >
                        {GetTextFromContent(this.state.contactInfo, "phoneSales")}
                      </List.Item>
                      <List.Item>{""}</List.Item>
                      <List.Item
                        style={{
                          color: "white"
                        }}
                      >
                        {"Horario de Atencíon"}
                      </List.Item>
                      <List.Item
                        style={{
                          color: "#939393"
                        }}
                      >
                        {GetTextFromContent(this.state.contactInfo, "topSchedule")}
                      </List.Item>
                      <List.Item
                        style={{
                          color: "#939393"
                        }}
                      >
                        {GetTextFromContent(this.state.contactInfo, "bottomSchedule")}
                      </List.Item>
                    </List>
                  </Grid.Column>
                  <Grid.Column>
                    <h3
                      style={{
                        textTransform: "uppercase",
                        color: "white",
                        fontWeight: "bold"
                      }}
                    >
                      Atención a clientes
                    </h3>
                    <List>
                      <List.Item
                        style={{
                          color: "#939393"
                        }}
                      >
                        {"En México:"}
                      </List.Item>
                      <List.Item
                        style={{
                          color: "#c43a0e"
                        }}
                        href={
                          "tel:" +
                          GetTextFromContent(this.state.contactInfo, "phoneClients")
                        }
                      >
                        {GetTextFromContent(this.state.contactInfo, "phoneClients")}
                      </List.Item>
                      <List.Item>{""}</List.Item>
                      <List.Item
                        style={{
                          color: "#939393"
                        }}
                      >
                        {"Desde el extranjero"}
                      </List.Item>
                      <List.Item
                        style={{
                          color: "#c43a0e"
                        }}
                        href={
                          "tel:" +
                          GetTextFromContent(this.state.contactInfo, "phoneForeign")
                        }
                      >
                        {GetTextFromContent(this.state.contactInfo, "phoneForeign")}
                      </List.Item>
                      <List.Item
                        style={{
                          color: "#939393"
                        }}
                      >
                        Correo electronico
                      </List.Item>
                      <List.Item
                        style={{
                          color: "#c43a0e"
                        }}
                        href={
                          "mailto:" +
                          GetTextFromContent(this.state.contactInfo, "contactMail")
                        }
                      >
                        {GetTextFromContent(this.state.contactInfo, "contactMail")}
                      </List.Item>
                    </List>
                    <Grid padded="horizontally" relaxed>
                      <Grid.Row only="mobile tablet">
                        <Button
                          arial-label={"Link a Facebook"}
                          href={GetLinkFromContent(this.state.linkInfo, "facebook")}
                          target={"_blank"}
                          circular
                          color="facebook"
                          icon="facebook"
                        />
                        <Button
                          arial-label={"Link a Twitter"}
                          href={GetLinkFromContent(this.state.linkInfo, "twitter")}
                          target={"_blank"}
                          circular
                          color="twitter"
                          icon="twitter"
                        />
                        <Button
                          arial-label={"Link a YouTube"}
                          href={GetLinkFromContent(this.state.linkInfo, "youtube")}
                          target={"_blank"}
                          circular
                          color="youtube"
                          icon="youtube"
                        />
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Container>
          <Divider
            style={{
              color: "#5c5c5c",
              backgroundColor: "#5c5c5c",
              margin: "3rem 0rem"
            }}
          />
          <Container>
            <Grid columns={"equal"} style={{ ...styleBasic, width: "100%" }}>
              <Grid.Column width={1} only={"mobile"} />
              <Grid.Column style={{ ...styleBasic }}>
                <Grid stackable columns={5}>
                  {/*SocialNetworks buttons */}
                  <Grid.Column
                    style={{ cursor: "default" }}
                    tablet={4}
                    mobile={5}
                    computer={5}
                  >
                    <div style={{ color: "#ffffff66", fontSize: "0.8rem" }} />
                  </Grid.Column>
                  <Grid.Column tablet={2} mobile={2} computer={2}>
                    <div
                      onClick={() => {
                        this.props.push("/terminos-y-condiciones");
                        window.scroll({
                          top: document.getElementById("nav").offsetTop,
                          behavior: "auto"
                        });
                      }}
                      style={{
                        cursor: "pointer",
                        color: "#ffffff66",
                        fontSize: "0.8rem"
                      }}
                    >
                      Términos y condiciones
                    </div>
                  </Grid.Column>
                  <Grid.Column tablet={2} mobile={3} computer={3}>
                    <div
                      onClick={() => {
                        this.props.push("/politica-de-privacidad");
                        window.scroll({
                          top: document.getElementById("nav").offsetTop,
                          behavior: "auto"
                        });
                      }}
                      style={{
                        cursor: "pointer",
                        color: "#ffffff66",
                        fontSize: "0.8rem"
                      }}
                    >
                      Políticas de privacidad
                    </div>
                  </Grid.Column>
                  <Grid.Column tablet={4} mobile={3} computer={3}>
                    <div style={{ color: "#ffffff66", fontSize: "0.8rem" }}>
                      CONSORCIO ARA S.A. DE C.V.
                    </div>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
        <div className="footer__contact-wrapper">
          <Button
            arial-label={"Contacto"}
            className="footer__contact-btn"
            onClick={this.handleClickContactModel}
          >
            <Icon name="envelope outline" size="large" />
            <span className="footer__contact-label">Contacto</span>
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.mainRender()}
        <ModalContact
          showModal={this.state.showContactModal}
          closeModal={this.handleClickContactModel}
        />
         <JobbankModal
          messageConfirm={this.state.jobBankInfo.messageConfirm}
          header={this.state.jobBankInfo.header}
          statusModal={this.state.showJobBankModal}
          onClose={this.handleClickJobBankModel}/>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    push: to => {
      dispatch(push(to));
    }
  };
};

const mapStateToProps = ({ data }) => {
  const { pages, api, conceptsURLs } = data;
  return {
    pages,
    conceptsURLs,
    api
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Footer)
);
