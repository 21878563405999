import React, { Component } from "react";
import gtmAdapter from "../utils/gtm/gtmAdapter";
import cookie from "react-cookies";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Button,
  Form,
  Grid,
  Input,
  Modal,
  Select,
  Responsive
} from "semantic-ui-react";
import API from "../API/API";
import ModalComponent from "../layouts/ModalComponent";
import { GetContent, GetPage, GetTextFromContent } from "../scripts";
import {
  styleBasic,
  styleCenteredObject,
  styleStartObject
} from "../style/inlineStyles";
import ModalSendEmail from "./ModalSendEmail";

import getPlatform from "../helpers/getPlatform";
import "../style/globalstyle.css";

export const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const genderSelector = [
  { id: 1, text: "Masculino", value: "m" },
  { id: 2, text: "Femenino", value: "f" }
];

const inputStyle = {
  ...styleBasic,
  ...styleCenteredObject,
  justifyContent: "flex-start",
  alignSelf: "flex-start",
  alignItems: "flex-start",
  width: "97%",
  height: "3.2rem",
  border: 0,
  borderRadius: "0.28571429rem",
  marginBottom: "2rem",
  borderBottom: "1px solid rgba(34,36,38,.15)",
  borderTop: "1px solid rgba(34,36,38,.15)",
  borderRight: "1px solid rgba(34,36,38,.15)",
  borderLeft: "1px solid rgba(34,36,38,.15)"
};

const inputStyleError = {
  ...styleBasic,
  ...styleCenteredObject,
  justifyContent: "flex-start",
  alignSelf: "flex-start",
  alignItems: "flex-start",
  width: "97%",
  height: "3.2rem",
  border: 0,
  borderRadius: "0.28571429rem",
  marginBottom: "2rem",
  color: "#9f3a38",
  borderBottom: "1px solid #9f3a38",
  borderTop: "1px solid #9f3a38",
  borderRight: "1px solid #9f3a38",
  borderLeft: "1px solid #9f3a38"
};

const dropDownStyle = {
  textAlign: "left",
  minHeight: "3rem",
  padding: ".67857143em 1em",
  borderRadius: "0.28571429rem",
  borderColor: "transparent",
  backgroundColor: "#F2F4F6"
};

const inputInside = {
  ...styleBasic,
  height: "3rem",
  width: "100%",
  textAlign: "left",
  padding: ".67857143em 1em",
  borderRadius: "0.28571429rem",
  borderColor: "transparent",
  backgroundColor: "#F2F4F6"
};

const columnStyle = {
  height: "3rem"
};

class ModalContact extends Component {
  dataLayer = {
    event: "Lead"
  };
  constructor(props) {
    super(props);

    this.state = {
      didTrySend: false,
      showEmailModal: false,
      showModal: false,
      interestedIn: "isEmail",
      affair: "contactar",
      affairData: "",
      name: "",
      lastname1: "",
      lastname2: "",
      gender: "",
      email: "",
      isValidEmail: false,
      submittedEmail: "",
      mobile: "",
      termsAndCondition: false,
      gaCookie: "",
      error: false,
      disabledSubmit: false
    };
    this.didGetData = false;
    this.contactInfo = {};
    this.renderTitle = this.renderTitle.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillMount() {
    const gaCookie = cookie.load("_ga");
    this.setState({ gaCookie: gaCookie });
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
    if (nextProps.showModal !== this.state.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }
  }

  verifyPages(pages) {
    if (pages.length > 0 && this.didGetData === false) {
      this.didGetData = true;
      const api = new API();
      const pageId = GetPage(pages, "internal").id;
      api.getContentOf(pageId).then(response => {
        this.contactInfo = GetContent(response[0], "main", "contactInfo");
        this.forceUpdate();
      });
    }
  }

  handleChange = (e, { name, value }) => {
    let error =
      (this.state.name === "" ||
        this.state.name.trim().length === 0 ||
        this.state.lastname1 === "" ||
        this.state.lastname1.trim().length === 0 ||
        this.state.lastname2 === "" ||
        this.state.lastname2.trim().length === 0 ||
        this.state.email === "" ||
        this.state.mobile.replace(/[^0-9]/g, "").toString().length < 10 ||
        this.state.interestedIn === "" ||
        this.state.affairData === "" ||
        this.state.affairData.trim().length === 0 ||
        this.state.gender === "" ||
        this.state.termsAndCondition === false) &&
      this.state.didTrySend;
    this.setState({ [name]: value, error: error });
  };

  handleSubmit = async () => {
    const {
      name,
      lastname1,
      lastname2,
      affairData,
      gender,
      email,
      mobile,
      termsAndCondition
    } = this.state;

    const { catalog, btnLabel, ...extraData } = this.props.extraData
      ? this.props.extraData
      : {};

    this.setState({ didTrySend: false });
    if (
      name === "" ||
      name.trim().length === 0 ||
      lastname1 === "" ||
      lastname1.trim().length === 0 ||
      lastname2 === "" ||
      lastname2.trim().length === 0 ||
      email === "" ||
      mobile.replace(/[^0-9]/g, "").toString().length < 10 ||
      affairData === "" ||
      affairData.trim().length === 0 ||
      gender === "" ||
      termsAndCondition === false
    ) {
      this.setState({ didTrySend: true, error: true });
      console.log("SHIT");
      return;
    }

    this.setState({
      disabledSubmit: !this.state.disabledSubmit
    });

    let tempCallHour = -1;
    let message = "Contactar para: Contactar Asesor | Mensaje: " + affairData;
    let openValue = "";
    let descCamp = "Orgánico";
    let descCampId = "-";
    if (this.props.idcheck.key !== undefined) {
      descCampId = this.props.idcheck.key;
      descCamp = this.props.idcheck.value;
    }
    let idOrigenWeb = "17";

    const dataPlatform = await getPlatform();

    const dataSend = {
      idFraccionamiento: -1,
      nombre: name,
      apellidoPaterno: lastname1,
      apellidoMaterno: lastname2,
      telefonoCasa: "",
      telefonoCelular: mobile.replace(/[^0-9]/g, ""),
      email: email,
      sexo: gender,
      fechaCita: "",
      mensaje: message,
      idCategoriaWeb: "1826",
      idOrigenWeb,
      idEdoNavWeb: "1",
      creditoContacto: "0",
      campoAbierto: openValue,
      origen: "ara",
      idCampania: descCampId,
      descCampania: descCamp,
      codigo: this.state.gaCookie,
      horario: tempCallHour,
      ...extraData,
      ...dataPlatform
    };

    const api = new API();

    api
      .contactARA(dataSend)
      .then(response => {
        gtmAdapter.dataLayerPush(this.dataLayer);
        this.setState({
          disabledSubmit: !this.state.disabledSubmit
        });
      })
      .catch(error => {});

    let phoneAdd = mobile;
    let nameSend = name + " " + lastname1 + " " + lastname2;
    const idFraccionamiento =
      dataSend.idFraccionamiento === -1 ? "" : dataSend.idFraccionamiento;

    api
      .sendEmailWelcome(nameSend, email, phoneAdd, idFraccionamiento)
      .then(response => {});

    this.setState({
      showEmailModal: true,
      submittedEmail: email,
      modalName: nameSend,
      modalEmail: email,
      modalTel: phoneAdd
    });

    if (catalog !== "" && catalog !== undefined) {
      window.open(catalog, "_blank");
    }
  };

  renderTitle() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          <h2
            style={{
              display: "flex",
              fontWeight: "normal",
              flexDirection: "column",
              textAlign: "left",
              color: "#363639"
            }}
          >
            {"Formulario"}
            <h2 style={{ fontWeight: "bold" }}>{"para contacto"}</h2>
          </h2>
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          <h1
            style={{
              display: "flex",
              fontWeight: "normal",
              flexDirection: "row",
              textAlign: "left",
              color: "#363639"
            }}
          >
            {"Formulario"}
            <h1 style={{ fontWeight: "bold", marginLeft: "0.3rem" }}>
              {"para contacto"}
            </h1>
          </h1>
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          <h1
            style={{
              display: "flex",
              fontWeight: "normal",
              flexDirection: "row",
              textAlign: "left",
              color: "#363639"
            }}
          >
            {"Formulario"}
            <h1 style={{ fontWeight: "bold", marginLeft: "0.3rem" }}>
              {"para contacto"}
            </h1>
          </h1>
        </Responsive>
      </div>
    );
  }

  renderBody() {
    return (
      <div>
        <Responsive {...Responsive.onlyTablet}>
          <h3
            style={{
              display: "flex",
              margin: "2rem 0rem",
              fontWeight: "normal",
              flexDirection: "row",
              color: "#363639"
            }}
          >
            {GetTextFromContent(this.contactInfo, "body")}
          </h3>
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          <h3
            style={{
              display: "flex",
              margin: "2rem 0rem",
              fontWeight: "normal",
              flexDirection: "row",
              color: "#363639"
            }}
          >
            {GetTextFromContent(this.contactInfo, "body")}
          </h3>
        </Responsive>
      </div>
    );
  }

  renderButton() {
    const btnLabel = this.props.extraData ? this.props.extraData.btnLabel : "";
    const submitBtnLabel = btnLabel || "ENVIAR MENSAJE";

    return (
      <div>
        <Responsive {...Responsive.onlyTablet}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <Button
              arial-label={submitBtnLabel}
              color="pink"
              floated="left"
              style={{
                height: "3rem",
                width: "16rem",
                backgroundColor: "#c43a0e",
                color: "white"
              }}
              onClick={this.handleSubmit}
              disabled={this.state.disabledSubmit}
            >
              {submitBtnLabel}
            </Button>
          </div>
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <Button
              arial-label={submitBtnLabel}
              color="pink"
              floated="left"
              style={{
                height: "3rem",
                width: "16rem",
                backgroundColor: "#c43a0e",
                color: "white"
              }}
              onClick={this.handleSubmit}
              disabled={this.state.disabledSubmit}
            >
              {submitBtnLabel}
            </Button>
          </div>
        </Responsive>
      </div>
    );
  }
  render() {
    const {
      didTrySend,
      name,
      lastname1,
      lastname2,
      gender,
      email,
      isValidEmail,
      interestedIn,
      affairData,
      mobile,
      termsAndCondition
    } = this.state;
    return (
      <ModalComponent
        closeSize={"1.3rem"}
        setBasic={false}
        width={"70%"}
        onOpen={() => {}}
        closeModal={() => {
          this.props.closeModal();
        }}
        backgroundColor={"#DDDEE0"}
        showModal={this.state.showModal}
      >
        <div style={{ padding: "0rem 2rem" }}>
          <Modal.Header>{this.renderTitle()}</Modal.Header>
          <Modal.Content>{this.renderBody()}</Modal.Content>
          <Modal.Content>
            <div>
              <Form>
                <Grid
                  columns="three"
                  divided
                  centered
                  stackable
                  style={{ ...styleBasic, marginBottom: "2rem" }}
                >
                  <Grid.Row style={{ ...styleBasic, minHeight: "3.7rem" }}>
                    <Grid.Column
                      style={{
                        ...styleBasic,
                        ...columnStyle
                      }}
                    >
                      <Input
                        fluid
                        label="Nombre"
                        placeholder="Nombre"
                        error={
                          (name === "" || name.trim().length === 0) &&
                          didTrySend === true
                        }
                        required
                        name="name"
                        value={name}
                        onChange={this.handleChange}
                        style={{
                          ...((name === "" || name.trim().length === 0) &&
                          didTrySend === true
                            ? inputStyleError
                            : inputStyle)
                        }}
                      >
                        <input style={{ ...inputInside }} />
                      </Input>
                    </Grid.Column>
                    <Grid.Column
                      style={{
                        ...styleBasic,
                        ...columnStyle
                      }}
                    >
                      <Input
                        fluid
                        label="Apellido Paterno"
                        placeholder="Apellido Paterno"
                        required
                        error={
                          (lastname1 === "" || lastname1.trim().length === 0) &&
                          didTrySend === true
                        }
                        name="lastname1"
                        value={lastname1}
                        onChange={this.handleChange}
                        style={{
                          ...((lastname1 === "" ||
                            lastname1.trim().length === 0) &&
                          didTrySend === true
                            ? inputStyleError
                            : inputStyle)
                        }}
                      >
                        <input style={{ ...inputInside }} />
                      </Input>
                    </Grid.Column>
                    <Grid.Column
                      style={{
                        ...styleBasic,
                        ...columnStyle
                      }}
                    >
                      <Input
                        fluid
                        label="Apellido Materno"
                        placeholder="Apellido Materno"
                        required
                        error={
                          (lastname2 === "" || lastname2.trim().length === 0) &&
                          didTrySend === true
                        }
                        name="lastname2"
                        value={lastname2}
                        onChange={this.handleChange}
                        style={{
                          ...((lastname2 === "" ||
                            lastname2.trim().length === 0) &&
                          didTrySend === true
                            ? inputStyleError
                            : inputStyle)
                        }}
                      >
                        <input style={{ ...inputInside }} />
                      </Input>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ ...styleBasic, minHeight: "3.7rem" }}>
                    <Grid.Column
                      style={{
                        ...styleBasic,
                        ...columnStyle
                      }}
                    >
                      <Input
                        fluid
                        label="Correo Electrónico"
                        placeholder="Correo Electrónico"
                        required
                        error={
                          (isValidEmail === false || email === "") &&
                          didTrySend === true
                        }
                        name="email"
                        value={email}
                        onChange={event => {
                          const checkEmail = validateEmail(event.target.value);
                          this.setState({
                            isValidEmail: checkEmail
                          });
                          this.handleChange(event, {
                            name: "email",
                            value: event.target.value
                          });
                        }}
                        style={{
                          ...((isValidEmail === false || email === "") &&
                          didTrySend === true
                            ? inputStyleError
                            : inputStyle)
                        }}
                      >
                        <input style={{ ...inputInside }} />
                      </Input>
                    </Grid.Column>
                    <Grid.Column
                      style={{
                        ...styleBasic,
                        ...columnStyle
                      }}
                    >
                      <Input
                        fluid
                        required
                        error={gender === "" && didTrySend === true}
                        style={{
                          ...(gender === "" && didTrySend === true
                            ? inputStyleError
                            : inputStyle)
                        }}
                      >
                        <Select
                          fluid
                          search
                          required
                          error={gender === "" && didTrySend === true}
                          name={"gender"}
                          value={gender}
                          options={genderSelector}
                          onChange={this.handleChange}
                          label="Género"
                          placeholder="Género"
                          style={{
                            ...dropDownStyle
                          }}
                        />
                      </Input>
                    </Grid.Column>
                    <Grid.Column
                      style={{
                        ...styleBasic,
                        ...columnStyle
                      }}
                    >
                      <Input
                        fluid
                        required
                        label="Teléfono Celular"
                        error={
                          mobile.replace(/[^0-9]/g, "").toString().length <
                            10 && didTrySend === true
                        }
                        name="mobile"
                        value={mobile}
                        style={{
                          ...(mobile.replace(/[^0-9]/g, "").toString().length <
                            10 && didTrySend === true
                            ? inputStyleError
                            : inputStyle),
                          marginBottom: "0rem"
                        }}
                      >
                        <InputMask
                          style={{ ...inputInside }}
                          placeholder="(55) 5555-5555"
                          mask="(99) 9999-9999"
                          name="mobile"
                          value={mobile}
                          onChange={event => {
                            this.handleChange(event, {
                              name: "mobile",
                              value: event.target.value
                            });
                          }}
                        />
                      </Input>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row
                    columns={1}
                    style={{ ...styleBasic, minHeight: "10rem" }}
                  >
                    <Grid.Column
                      stretched
                      style={{
                        ...styleBasic,
                        height: "10rem"
                      }}
                    >
                      <Input
                        fluid
                        label="Mensaje"
                        placeholder="Mensaje"
                        required={true}
                        name="affairData"
                        value={affairData}
                        onChange={this.handleChange}
                        error={
                          interestedIn === "isEmail" &&
                          affairData === "" &&
                          didTrySend === true
                        }
                        style={{
                          ...(interestedIn === "isEmail" &&
                          affairData === "" &&
                          didTrySend === true
                            ? inputStyleError
                            : inputStyle),
                          marginBottom: "0rem",
                          width: "99%",
                          height: "100%",
                          ...styleStartObject
                        }}
                      >
                        <input
                          style={{
                            ...inputInside,
                            ...styleStartObject,
                            height: "100%"
                          }}
                        />
                      </Input>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row
                    only={"computer tablet"}
                    columns={"equal"}
                    style={{
                      ...styleBasic,
                      ...styleCenteredObject,
                      marginTop: "2rem"
                    }}
                  >
                    <Grid.Column
                      style={{
                        ...styleBasic,
                        ...styleCenteredObject,
                        justifyContent: "flex-start"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start"
                        }}
                      >
                        <Form.Checkbox
                          align="center"
                          error={
                            termsAndCondition === false && didTrySend === true
                          }
                          name="termsAndCondition"
                          value={termsAndCondition}
                          onChange={() => {
                            let error =
                              (name === "" ||
                                lastname1 === "" ||
                                lastname2 === "" ||
                                email === "" ||
                                mobile === "" ||
                                interestedIn === "" ||
                                affairData === "" ||
                                gender === "" ||
                                !termsAndCondition === false) &&
                              didTrySend;
                            this.setState({
                              termsAndCondition: !termsAndCondition,
                              error: error
                            });
                          }}
                          label=""
                        />
                        <div style={{ fontSize: "13px", textAlign: "left" }}>
                          Estoy de acuerdo con los{" "}
                          <a href="terminos-y-condiciones">
                            Términos del Servicio
                          </a>{" "}
                          y{" "}
                          <a href="politica-de-privacidad">
                            Política de Privacidad
                          </a>
                          . <t style={{ color: "red" }}>*</t>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      style={{
                        ...styleBasic,
                        ...styleCenteredObject,
                        justifyContent: "flex-end"
                      }}
                    >
                      {this.renderButton()}
                    </Grid.Column>
                  </Grid.Row>
                  <Responsive
                    {...Responsive.onlyMobile}
                    className="contact-form__wrapper-btn-send"
                  >
                    <div
                      style={{
                        ...styleBasic,
                        ...styleCenteredObject,
                        width: "100%",
                        flexDirection: "column"
                      }}
                    >
                      <div
                        style={{
                          ...styleBasic,
                          ...styleCenteredObject,
                          justifyContent: "flex-start"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start"
                          }}
                        >
                          <Form.Checkbox
                            align="center"
                            error={
                              termsAndCondition === false && didTrySend === true
                            }
                            name="termsAndCondition"
                            value={termsAndCondition}
                            onChange={() => {
                              let error =
                                (name === "" ||
                                  lastname1 === "" ||
                                  lastname2 === "" ||
                                  email === "" ||
                                  mobile === "" ||
                                  interestedIn === "" ||
                                  affairData === "" ||
                                  gender === "" ||
                                  !termsAndCondition === false) &&
                                didTrySend;
                              this.setState({
                                termsAndCondition: !termsAndCondition,
                                error: error
                              });
                            }}
                            label=""
                          />
                          <div style={{ fontSize: "11px", textAlign: "left" }}>
                            Estoy de acuerdo con los{" "}
                            <a href="terminos-y-condiciones">
                              Términos del Servicio
                            </a>{" "}
                            y{" "}
                            <a href="politica-de-privacidad">
                              Política de Privacidad
                            </a>
                            . <t style={{ color: "red" }}>*</t>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          ...styleBasic,
                          ...styleCenteredObject,
                          width: "100%"
                        }}
                      >
                        <Button
                          arial-label={"Enviar Mensaje"}
                          color="pink"
                          floated="left"
                          style={{
                            height: "3rem",
                            width: "100%",
                            backgroundColor: "#c43a0e",
                            color: "white"
                          }}
                          onClick={this.handleSubmit}
                          disabled={this.state.disabledSubmit}
                        >
                          {"ENVIAR MENSAJE"}
                        </Button>
                        <div style={{ height: "7rem" }} />
                      </div>
                    </div>
                  </Responsive>
                </Grid>
              </Form>
            </div>
          </Modal.Content>
        </div>
        <ModalSendEmail
          showModal={this.state.showEmailModal}
          closeModal={() => {
            this.setState({
              didTrySend: false,
              showEmailModal: false,
              interestedIn: "isEmail",
              affair: "contactar",
              affairData: "",
              name: "",
              lastname1: "",
              lastname2: "",
              gender: "",
              email: "",
              isValidEmail: false,
              submittedEmail: "",
              mobile: "",
              termsAndCondition: false,
              gaCookie: "",
              error: false
            });
            this.props.closeModal();
          }}
          name={this.state.modalName}
          email={this.state.modalEmail}
          phone={this.state.modalTel}
        />
      </ModalComponent>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { idcheck, pages } = data;
  return {
    idcheck,
    pages
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(ModalContact)
);
