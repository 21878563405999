import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import {
  Container,
  List,
  Responsive,
  Accordion,
  Icon,
  Transition
} from "semantic-ui-react";
import "../style/globalstyle.css";

class MenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.options = [];
  }

  handleItemClick = navigateto => {
    this.props.push(navigateto);
  };

  state = { activeIndex: 0, activeItem: "home", visible: false };

  handleClicka = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  renderInMobile() {
    const { activeIndex, activeItem, visible } = this.state;

    return (
      <Container>
        <List className="animatedmenu">
          {this.props.listitem.map(item => {
            return (
              <List.Item
                key={item.id}
                active={activeItem === item.name}
                onClick={() => {
                  this.props.onClickMobileMenu();
                  this.handleItemClick(item.navigateto);
                }}
                style={{
                  paddingTop: "2rem",
                  fontSize: "1.5rem"
                }}
              >
                {item.name}
              </List.Item>
            );
          })}
        </List>
      </Container>
    );
  }

  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { pages } = data;
  return {
    pages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: to => {
      dispatch(push(to));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuItems)
);
