import platform from 'platform';
import fetch from 'isomorphic-fetch'

//api option
// https://ipapi.co/json/
//http://www.geoplugin.net/json.gp?jsoncallback=?

function getIp () {

    return new Promise((resolve, reject) => {
        fetch('https://ipapi.co/json/',{
            method: 'GET'
        })
        .then((response) => {
            resolve(response.json());
        })
        .catch((err) => {
            reject(err);
        });
    });
}

export default async function () {

    const ipInfo = await getIp();
    
    return {
        platformName: platform.name,
        platformVersion: platform.version,
        platformProduct: platform.product,
        platformOsArchitecture: platform.os.architecture,
        platformOsFamily: platform.os.family,
        platformOsVersion: platform.os.version,
        platformIp: ipInfo.ip,
        platformCity: ipInfo.city,
        platformRegion: ipInfo.region
    }
}