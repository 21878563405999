import "core-js/es6/symbol";
import "core-js/es6/object";
import "core-js/es6/function";
import "core-js/es6/parse-int";
import "core-js/es6/parse-float";
import "core-js/es6/number";
import "core-js/es6/math";
import "core-js/es6/string";
import "core-js/es6/date";
import "core-js/es6/array";
import "core-js/es6/regexp";
import "core-js/es6/map";
import "core-js/es6/weak-map";
import "core-js/es6/set";
import "core-js/fn/object/assign";
import "core-js/fn/promise";
import "isomorphic-fetch";
import "es6-promise/auto";
import "raf/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./style/quill.snow.css";
import "semantic-ui-css/semantic.min.css";
import "./style/sass/style.scss";
//import * as serviceWorker from "./serviceWorker";
/*import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "GTM-THTXCSD"
};
TagManager.initialize(tagManagerArgs);*/

ReactDOM.render(<App />, document.getElementById("root"));
//serviceWorker.register();
