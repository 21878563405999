import RestClient from "./RestClient";
import { finalIP as ip } from "../scripts";

export const typeDevelopmentSelector = [
  {
    key: "home",
    value: "home",
    text: "Casas"
  },
  {
    key: "department",
    value: "department",
    text: "Departamentos"
  },
  {
    key: "department&home",
    value: "department&home",
    text: "Casas y Departamentos"
  }
];


export const getDepartmentType = value => {
  let returnData = "";
  const findData = typeDevelopmentSelector.find(check => {
    return check.value === value;
  });
  if (findData !== undefined) {
    returnData = findData.text;
  }
  return returnData;
};


export const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
});

export const mainIp = ip;

export default class API extends RestClient {
  constructor(authToken) {
    super(mainIp + "/api", {
      headers: {
        // Include as many custom headers as you need
        Authorization: authToken
      }
    });
    this.authToken = authToken;
    this.idOrganizationGod = "";
  }
  getToken() {
    return this.authToken;
  }

  getPages(nameOrg, menuTitle) {
    const url = "/Organizations/getPages";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg, menuTitle: menuTitle });
  }

  getContentOf(idPage) {
    const url = "/Organizations/getContentOf";
    return this.GETPASSVALUE(url, { idPage: idPage });
  }

  getImageOf(idPage) {
    const url = "/Organizations/getImageOf";
    return this.GETPASSVALUE(url, { idPage: idPage });
  }

  getHomeModel(homeModelId) {
    const url = "/Organizations/getHomeModel";
    return this.GETPASSVALUE(url, { homeModelId: homeModelId });
  }

  getOrg() {
    const url = "/Organizations";
    return this.GET(url);
  }

  sendMail(email, body, subject) {
    const url = "/Organizations/sendEmail";
    return this.POST(url, { email, body, subject });
  }

  getConceptsURLs(nameOrg) {
    const url = "/Organizations/getConceptsURLs";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getStatesURLs(nameOrg) {
    const url = "/Organizations/getStatesURLs";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getDevelopmentsURLs(nameOrg) {
    const url = "/Organizations/getDevelopmentsURLs";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getHomeModelsURLs(nameOrg) {
    const url = "/Organizations/getHomeModelsURLs";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getCreditsURLs(nameOrg) {
    const url = "/Organizations/getCreditsURLs";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getCreditTypesURLs(nameOrg) {
    const url = "/Organizations/getCreditTypesURLs";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getBlogsURLs(nameOrg) {
    const url = "/Organizations/getBlogsURLs";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getStates(nameOrg) {
    const url = "/Organizations/getStates";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getHomeStates(nameOrg) {
    const url = "/Organizations/getHomeStates";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getState(stateId) {
    const url = "/Organizations/getState";
    return this.GETPASSVALUE(url, { stateId: stateId });
  }

  getConcepts(nameOrg) {
    const url = "/Organizations/getConcepts";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getConcept(conceptId) {
    const url = "/Organizations/getConcept";
    return this.GETPASSVALUE(url, { conceptId: conceptId });
  }

  getDevelopmentsForMain(nameOrg) {
    const url = "/Organizations/getDevelopmentsForMain";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getDevelopmentsForConcept(conceptId) {
    const url = "/Organizations/getDevelopmentsForConcept";
    return this.GETPASSVALUE(url, { conceptId: conceptId });
  }

  getDevelopmentsForState(stateId) {
    const url = "/Organizations/getDevelopmentsForState";
    return this.GETPASSVALUE(url, { stateId: stateId });
  }

  getDevelopment(developmentId) {
    const url = "/Organizations/getDevelopment";
    return this.GETPASSVALUE(url, { developmentId: developmentId });
  }

  getCredits(nameOrg) {
    const url = "/Organizations/getCredits";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getCredit(creditId) {
    const url = "/Organizations/getCredit";
    return this.GETPASSVALUE(url, { creditId: creditId });
  }

  getHomeCredits(nameOrg) {
    const url = "/Organizations/getHomeCredits";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getCreditType(creditTypeId) {
    const url = "/Organizations/getCreditType";
    return this.GETPASSVALUE(url, { creditTypeId: creditTypeId });
  }

  getAmenities(nameOrg) {
    const url = "/Organizations/getAmenities";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getAmenitiesByState(idState) {
    const url = "/Organizations/getAmenitiesByState";
    return this.GETPASSVALUE(url, { idState: idState });
  }

  getBlogs(nameOrg, pag, sizePag) {
    const url = "/Organizations/getBlogs";
    return this.GETPASSVALUE(url, {
      nameOrg: nameOrg,
      pag: pag,
      sizePag: sizePag
    });
  }

  getFeaturedBlogs() {
    const url = "/Organizations/getBlogfeatured";
    return this.GET(url);
  }

  getHomeBlogs(nameOrg) {
    const url = "/Organizations/getHomeBlogs";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getBlog(blogId) {
    const url = "/BlogEntries/" + blogId;
    return this.GET(url, {
      include: ['blogTags']
    });
  }

  getPromotions(nameOrg) {
    const url = "/Organizations/getPromotions";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  contactARA(data) {
    const url = "/Organizations/insertaContactoExterno";
    return this.POST(url, { data: data });
  }

  getInformacionLugaresNacimiento() {
    const url = "/Organizations/getInformacionLugaresNacimiento";
    return this.GET(url);
  }

  sendEmailWelcome(name, email, phone, idFraccionamiento = '') {
    const url = "/Organizations/sendEmailWelcome";
  
    return this.POST(url, { name: name, email: email, phone: phone, idFraccionamiento: idFraccionamiento.toString() });
  }

  getBlogCategories(limit){
    const url ='/BlogCategories';

    return this.GET(url, {
      limit: limit
    });
  }
  
  getBlogTags(limit){
    const url ='/BlogTags';

    return this.GET(url, {
      limit: limit
    });
  }

  getPostsMoreVisited(limit){
    const url ='/BlogEntries/mostVisited';

    return this.GETPASSVALUE(url, {
      limit: limit
    });
  }

  getPosts(page = 0, sizePage = 10 , categories = [], tags = []){
    const url ='/BlogEntries/classifiedPosts';

    return this.GETPASSVALUE(url, {
      categories,
      page,
      sizePage,
      tags
    });
  }

  getNearbyPosts(id){
    const url ='/BlogEntries/nearby';

    return this.GETPASSVALUE(url, {
      id
    });
  }

  getSearchPosts(keyword = '', page = 0, sizePage = 10){
    const url ='/BlogEntries/search';

    return this.GETPASSVALUE(url, {
      page,
      sizePage,
      keyword
    });

  }

  getStatesLikeOption(){
    const url = "/States/likeOption";
    
    return this.GET(url);
  }

  sendJob(data){
    const url = "/Jobs/sendJob";

    return this.POST(url, { data });
  }
}

