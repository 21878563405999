
/**
 * This is a adapter for the GTM script
 * Here it must add the logic to comunicate with the GTM API 
 * */
export default class gtmAdapter{

    static dataLayerPush(dataObj){

        gtmAdapter._validateDataLayer();

        window.dataLayer.push(dataObj);

    }

    static _validateDataLayer(){
        window.dataLayer = window.dataLayer || [];
    }

}
