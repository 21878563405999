import logoc from "./logo.png";
import logos from "./logo_small.png";
import casasaralogowhite01 from "./casasaralogowhite.png";
import imgfooter01 from "./footerimg1.png";
import imgfooter02 from "./footerimg2.png";
import imgfooter03 from "./footerimg3.png";
import imgfooter04 from "./footerimg4.png";
import contactimg01 from "./contactimg.png";
import searchicon01 from "./searchicon.png";
import room01 from "./rooms.png";
import bathroom01 from "./bathrooms.png";
import area01 from "./area.png";
import parking01 from "./parking.png";
import emptyView from "./blank.png";
import legal from "./legalbg.jpg";
import base from "./basebg.jpg";
import wazeic from "./wazeicon.png";
import googlemapsic from "./googlemapsicon.png";

export const logo = logoc;
export const logosmall = logos;
export const imgfooter1 = imgfooter01;
export const imgfooter2 = imgfooter02;
export const imgfooter3 = imgfooter03;
export const imgfooter4 = imgfooter04;
export const logowhite = casasaralogowhite01;
export const contactimg1 = contactimg01;
export const searchicon = searchicon01;
export const room = room01;
export const bathroom = bathroom01;
export const area = area01;
export const parking = parking01;
export const blank = emptyView;
export const legalBG = legal;
export const baseBG = base;
export const wazeicon = wazeic;
export const googlemapsicon = googlemapsic;
