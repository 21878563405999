
const asc='ASC',
      desc='DESC';


export default function (itemArray , att, sort=asc ){

    if(!Array.isArray(itemArray)){
        throw new Error("Invalid data: 'itemArray' property expects an array" );
    }

    if((typeof att) !== 'string' && (typeof att) !== 'number' ){
        throw new Error("Invalid data: 'att' property expects a string or number" );
    }

    const sortArray = itemArray.sort(function (a, b) {
        if (a[att] > b[att]) {
          return (sort === asc )? 1 : (sort === desc)? -1 : 1;
        }

        if (a[att] < b[att]) {
            return (sort === asc )? -1 : (sort === desc)? 1 : -1;
        }
        // a must be equal to b
        return 0;
      });

      return sortArray;
}