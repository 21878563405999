import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { Icon, Responsive, Transition } from "semantic-ui-react";
import API from "./API/API";
import { asyncComponent } from "./components/AsyncComponent";
import Footer from "./components/Footer";
import IDFromURLStart from "./components/IDFromURLStart";
import MenuComp from "./components/MenuComp";
import MenuItems from "./components/MenuItems";
import {
  setAmenities,
  setAPI,
  setBlogs,
  setBlogsURLs,
  setConceptsURLs,
  setCreditsURLs,
  setCreditTypesURLs,
  setDevelopmentsURLs,
  setHomeModelsURLs,
  setIDCheck,
  setPages,
  setPromotions,
  setStatesURLs
} from "./redux/actions";
import { GetContent, GetPage, GetTextFromContent } from "./scripts";
import "./style/globalstyle.css";
import { styleBasic, styleCenteredObject } from "./style/inlineStyles";

const HomePage = asyncComponent(
  import("./pages/HomePage" /* webpackChunkName: "HomePage" */)
);
const AboutUs = asyncComponent(
  import("./pages/AboutUs" /* webpackChunkName: "AboutUs" */)
);
const BlogPage = asyncComponent(
  import("./pages/BlogPage" /* webpackChunkName: "BlogPage" */)
);
const ConceptosPage = asyncComponent(
  import("./pages/ConceptosPage" /* webpackChunkName: "ConceptosPage" */)
);
const ContactPage = asyncComponent(
  import("./pages/ContactPage" /* webpackChunkName: "ContactPage" */)
);
const CreditPage = asyncComponent(
  import("./pages/CreditPage" /* webpackChunkName: "CreditPage" */)
);
const DesarrollosPage = asyncComponent(
  import("./pages/DesarrollosPage" /* webpackChunkName: "DesarrollosPage" */)
);
const DetalleBlogPage = asyncComponent(
  import("./pages/DetalleBlogPage" /* webpackChunkName: "DetalleBlogPage" */)
);
const DetalleCreditPage = asyncComponent(
  import(
    "./pages/DetalleCreditPage" /* webpackChunkName: "DetalleCreditPage" */
  )
);
const DetalleCreditTypePage = asyncComponent(
  import(
    "./pages/DetalleCreditTypePage" /* webpackChunkName: "DetalleCreditTypePage" */
  )
);
const DetalleDesarrolloPage = asyncComponent(
  import(
    "./pages/DetalleDesarrolloPage" /* webpackChunkName: "DetalleDesarrolloPage" */
  )
);
const DetalleModeloPage = asyncComponent(
  import(
    "./pages/DetalleModeloPage" /* webpackChunkName: "DetalleModeloPage" */
  )
);
const LegalPage = asyncComponent(
  import("./pages/LegalPage" /* webpackChunkName: "LegalPage" */)
);
const PromocionesPage = asyncComponent(
  import("./pages/PromocionesPage" /* webpackChunkName: "PromocionesPage" */)
);
const StateConceptPage = asyncComponent(
  import("./pages/StateConceptPage" /* webpackChunkName: "StateConceptPage" */)
);
const NotFound = asyncComponent(
  import("./pages/NotFound" /* webpackChunkName: "NotFound" */)
);

const menuoptions = [
  {
    id: 2,
    name: "Desarrollos",
    navigateto: "/estados"
  },
  {
    id: 4,
    name: "Conceptos",
    navigateto: "/conceptos"
  },
  {
    id: 3,
    name: "Promociones",
    navigateto: "/promociones"
  },
  {
    id: 5,
    name: "Créditos",
    navigateto: "/credito"
  },
  {
    id: 6,
    name: "Blog",
    navigateto: "/articulos-y-noticias"
  },
  {
    id: 7,
    name: "Contacto",
    navigateto: "/contacto"
  }
];

class Navigator extends Component {
  constructor(props) {
    super(props);
    this.getAPIAll = this.getAPIAll.bind(this);
    this.contactInfo = {};
  }

  state = {
    activeItem: "Inicio",
    mobileMenuVisible: false,
    didGetAllData: false
  };

  getAPIAll() {
    let currentCounter = 0;
    const api = new API();
    this.props.setAPI(api);
    api.getPages("Casas Ara", "principal").then(responsePages => {
      this.props.setPages(responsePages[0].pages);
      const pageId = GetPage(responsePages[0].pages, "internal").id;
      api.getContentOf(pageId).then(responseInternal => {
        this.contactInfo = GetContent(responseInternal[0], "contact", "main");
      });
    });

    api.getStatesURLs("Casas Ara").then(responseStates => {
      this.props.setStatesURLs(responseStates);
      currentCounter += 1;
      if (currentCounter >= 7) {
        this.setState({ didGetAllData: true });
      }
    });

    api.getConceptsURLs("Casas Ara").then(responseConcepts => {
      this.props.setConceptsURLs(responseConcepts[0].concepts);
      currentCounter += 1;
      if (currentCounter >= 7) {
        this.setState({ didGetAllData: true });
      }
    });

    api.getDevelopmentsURLs("Casas Ara").then(responseDevelopments => {
      this.props.setDevelopmentsURLs(responseDevelopments[0].developments);
      currentCounter += 1;
      if (currentCounter >= 7) {
        this.setState({ didGetAllData: true });
      }
    });

    api.getHomeModelsURLs("Casas Ara").then(responseHomeModels => {
      this.props.setHomeModelsURLs(responseHomeModels[0].homeModels);
      currentCounter += 1;
      if (currentCounter >= 7) {
        this.setState({ didGetAllData: true });
      }
    });

    api.getCreditsURLs("Casas Ara").then(responseCredits => {
      this.props.setCreditsURLs(responseCredits[0].credits);
      currentCounter += 1;
      if (currentCounter >= 7) {
        this.setState({ didGetAllData: true });
      }
    });

    api.getCreditTypesURLs("Casas Ara").then(responseCreditTypes => {
      this.props.setCreditTypesURLs(responseCreditTypes[0].creditTypes);
      currentCounter += 1;
      if (currentCounter >= 7) {
        this.setState({ didGetAllData: true });
      }
    });

    api.getBlogsURLs("Casas Ara").then(responseBlogEntries => {
      this.props.setBlogsURLs(responseBlogEntries[0].blogEntries);
      currentCounter += 1;
      if (currentCounter >= 7) {
        this.setState({ didGetAllData: true });
      }
    });
  }

  getAPIAllByParts() {
    const api = new API();
    this.props.setAPI(api);
    api.getPages("Casas Ara", "principal").then(responsePages => {
      this.props.setPages(responsePages[0].pages);
      const pageId = GetPage(responsePages[0].pages, "internal").id;
      api.getContentOf(pageId).then(responseInternal => {
        this.contactInfo = GetContent(responseInternal[0], "contact", "main");
        api.getStatesURLs("Casas Ara").then(responseStates => {
          this.props.setStatesURLs(responseStates);
          api.getConceptsURLs("Casas Ara").then(responseConcepts => {
            this.props.setConceptsURLs(responseConcepts[0].concepts);
            api.getDevelopmentsURLs("Casas Ara").then(responseDevelopments => {
              this.props.setDevelopmentsURLs(
                responseDevelopments[0].developments
              );
              api.getHomeModelsURLs("Casas Ara").then(responseHomeModels => {
                this.props.setHomeModelsURLs(responseHomeModels[0].homeModels);
                api.getCreditsURLs("Casas Ara").then(responseCredits => {
                  this.props.setCreditsURLs(responseCredits[0].credits);
                  api
                    .getCreditTypesURLs("Casas Ara")
                    .then(responseCreditTypes => {
                      this.props.setCreditTypesURLs(
                        responseCreditTypes[0].creditTypes
                      );
                      api
                        .getBlogsURLs("Casas Ara")
                        .then(responseBlogEntries => {
                          this.props.setBlogsURLs(
                            responseBlogEntries[0].blogEntries
                          );
                          this.setState({ didGetAllData: true });
                        });
                    });
                });
              });
            });
          });
        });
      });
    });
  }

  componentWillMount() {
    this.getAPIAll();
    //this.getAPIAllByParts();
  }

  renderPages() {
    if (this.state.mobileMenuVisible === false) {
      return (
        <div>
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/estados" exact component={DesarrollosPage} />
            <Route path="/promociones" exact component={PromocionesPage} />
            <Route path="/contacto" exact component={ContactPage} />
            <Route path="/credito" exact component={CreditPage} />
            <Route
              path="/articulos-y-noticias"
              exact
              component={BlogPage}
            />
            <Route path="/conceptos" exact component={ConceptosPage} />
            <Route path="/acerca-de" exact component={AboutUs} />
            <Route path={"/terminos-y-condiciones"} exact>
              <LegalPage
                title={"Términos y condiciones"}
                getFrom={"termnsAndConditions"}
              />
            </Route>

            <Route path={"/politica-de-privacidad"} exact>
              <LegalPage
                title={"Política de Privacidad Integral"}
                getFrom={"privacyPolicy"}
              />
            </Route>

            <Route path={"/terminos-y-condiciones-bolsa-de-trabajo"} exact>
              <LegalPage
                getFrom={"tAndCJobBank"}
              />
            </Route>
            
            <Route path={"/politica-de-privacidad-bolsa-de-trabajo"} exact>
              <LegalPage
                getFrom={"pPJobBank"}
              />
            </Route>

            {this.props.conceptsURLs.map((concept, index) => {
              return (
                <Route key={index} path={"/" + concept.url.trim()} exact>
                  <StateConceptPage isState={false} />
                </Route>
              );
            })}
            {this.props.statesURLs.map((state, index) => {
              return (
                <Route key={index} path={"/" + state.url.trim()} exact>
                  <StateConceptPage isState={true} />
                </Route>
              );
            })}
            {this.props.developmentsURLs.map((development, index) => {
              return (
                <Route
                  key={index}
                  path={"/" + development.url.trim()}
                  exact
                  component={DetalleDesarrolloPage}
                />
              );
            })}
            {this.props.homemodelsURLs.map((homemodel, index) => {
              return (
                <Route
                  key={index}
                  path={"/" + homemodel.url.trim()}
                  exact
                  component={DetalleModeloPage}
                />
              );
            })}
            {this.props.creditsURLs.map((credit, index) => {
              return (
                <Route
                  key={index}
                  path={"/credito/" + credit.url.trim()}
                  exact
                  component={DetalleCreditPage}
                />
              );
            })}
            {this.props.creditTypesURLs.map((creditType, index) => {
              return (
                <Route
                  key={index}
                  path={"/credito/" + creditType.url.trim()}
                  exact
                  component={DetalleCreditTypePage}
                />
              );
            })}
            {this.props.blogsURLs.map((blogEntrie, index) => {
              let url = blogEntrie.id;
              if (blogEntrie.url !== undefined && blogEntrie.url !== "") {
                url = blogEntrie.url;
              }
              return (
                <Route
                  key={index}
                  path={"/articulos-y-noticias/" + url.trim()}
                  exact
                  component={DetalleBlogPage}
                />
              );
            })}
            {this.state.didGetAllData && (
              <Redirect from="/tipos-de-credito" to={"/credito"} />
            )}
            {this.state.didGetAllData && (
              <Route path="*" component={NotFound} />
            )}
          </Switch>
        </div>
      );
    }
  }

  render() {
    return (
      <IDFromURLStart
        idsCheck={[
          { key: "caid", imp: 4 },
          {
            key: "utm_source",
            imp: 3,
            value: ["utm_medium", "utm_campaign", "utm_term", "utm_content"]
          },
          { key: "referido", imp: 2 },
          { key: "fbclid", imp: 1 }
        ]}
        onGetIDCheck={idcheck => {
          this.props.setIDCheck(idcheck);
        }}
      >
        {this.state.didGetAllData && (
          <div>
            <MenuComp
              listitem={menuoptions}
              onClickMobileMenu={() => {
                this.setState({
                  mobileMenuVisible: !this.state.mobileMenuVisible
                });
              }}
              mobileMenuVisible={this.state.mobileMenuVisible}
            />
            <Responsive {...Responsive.onlyMobile}>
              <Transition
                visible={this.state.mobileMenuVisible}
                animation="fade"
                duration={300}
              >
                <div className="menum" align="center">
                  <MenuItems
                    onClickMobileMenu={() => {
                      this.setState({
                        mobileMenuVisible: !this.state.mobileMenuVisible
                      });
                    }}
                    listitem={menuoptions}
                  />
                  <div
                    href={
                      "tel:" +
                      GetTextFromContent(this.contactInfo, "phoneSales")
                    }
                    style={{
                      ...styleBasic,
                      ...styleCenteredObject,
                      marginTop: "2rem",
                      flexDirection: "row",
                      width: "100%",
                      height: "2rem",
                      fontWeight: "700",
                      fontSize: "1.5rem"
                    }}
                  >
                    {"Contáctanos"}
                  </div>
                  <div
                    href={
                      "tel:" +
                      GetTextFromContent(this.contactInfo, "phoneSales")
                    }
                    style={{
                      ...styleBasic,
                      ...styleCenteredObject,
                      flexDirection: "row",
                      width: "100%",
                      height: "2rem",
                      fontWeight: "500",
                      fontSize: "1.2rem"
                    }}
                  >
                    <Icon name="phone" />
                    {GetTextFromContent(this.contactInfo, "phoneSales")}
                  </div>
                </div>
              </Transition>
            </Responsive>
            {this.renderPages()}
            <Footer extraFooter />
          </div>
        )}
      </IDFromURLStart>
    );
  }
}

const mapStateToProps = ({ state, data }) => {
  const {
    pages,
    statesURLs,
    conceptsURLs,
    developmentsURLs,
    homemodelsURLs,
    creditsURLs,
    creditTypesURLs,
    blogsURLs
  } = data;
  return {
    state,
    statesURLs,
    conceptsURLs,
    developmentsURLs,
    homemodelsURLs,
    pages,
    creditsURLs,
    creditTypesURLs,
    blogsURLs
  };
};

const mapDispatchProps = dispatch => {
  return {
    setPages: pages => {
      dispatch(setPages(pages));
    },
    setIDCheck: idcheck => {
      dispatch(setIDCheck(idcheck));
    },
    setStatesURLs: statesURLs => {
      dispatch(setStatesURLs(statesURLs));
    },
    setConceptsURLs: conceptsURLs => {
      dispatch(setConceptsURLs(conceptsURLs));
    },
    setDevelopmentsURLs: developmentsURLs => {
      dispatch(setDevelopmentsURLs(developmentsURLs));
    },
    setHomeModelsURLs: homemodelsURLs => {
      dispatch(setHomeModelsURLs(homemodelsURLs));
    },
    setBlogsURLs: blogsURLs => {
      dispatch(setBlogsURLs(blogsURLs));
    },
    setAPI: api => {
      dispatch(setAPI(api));
    },
    setAmenities: amenities => {
      dispatch(setAmenities(amenities));
    },
    setCreditsURLs: creditsURLs => {
      dispatch(setCreditsURLs(creditsURLs));
    },
    setCreditTypesURLs: creditTypesURLs => {
      dispatch(setCreditTypesURLs(creditTypesURLs));
    },
    setBlogs: blogs => {
      dispatch(setBlogs(blogs));
    },
    setPromotions: promotions => {
      dispatch(setPromotions(promotions));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchProps
  )(Navigator)
);
