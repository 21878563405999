import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { Grid, Icon, Menu, Responsive } from "semantic-ui-react";
import API from "../API/API";
import { logo } from "../assets/Assets";
import GridForContent from "../layouts/GridForContent";
import { GetContent, GetPage, GetTextFromContent } from "../scripts";
import { styleBasic, styleCenteredObject } from "../style/inlineStyles";

class MenuComp extends React.Component {
  constructor(props) {
    super(props);
    this.renderInMobile = this.renderInMobile.bind(this);
    this.mainRender = this.mainRender.bind(this);
    this.state = {
      mobileMenuVisible: false,
      activeItem: "",
      language: true,
      open: true
    };
    this.options = [];
    this.verifyPages = this.verifyPages.bind(this);
    this.contactInfo = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "internal").id;

      api.getContentOf(pageId).then(response => {
        this.contactInfo = GetContent(response[0], "contact", "main");
        this.forceUpdate();
      });
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  handleItemClick = (name, navigateto) => {
    this.setState({ activeItem: name });
    this.props.push(navigateto);
  };

  mainRender() {
    const { activeItem } = this.state;

    return (
      <div className="menubar__vrapper-lg">
        <GridForContent>
          <Grid
            columns={"equal"}
            verticalAlign="middle"
            style={{
              ...styleBasic,
              width: "100%",
              height: "100%"
            }}
          >
            <Grid.Column
              width={2}
              only="computer tablet"
              verticalAlign="middle"
              style={{
                ...styleBasic,
                height: "100%"
              }}
            >
              <div
                style={{
                  ...styleBasic,
                  ...styleCenteredObject,
                  width: "100%",
                  height: "100%"
                }}
              >
                <div
                  onClick={() => {
                    this.props.push("");
                  }}
                  style={{
                    cursor: "pointer",
                    backgroundImage: "url(" + logo + ")",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100%",
                    backgroundRepeat: "no-repeat"
                  }}
                />
              </div>
            </Grid.Column>
            <Grid.Column
              verticalAlign="middle"
              style={{
                ...styleBasic,
                height: "100%"
              }}
            >
              <div
                style={{
                  ...styleBasic,
                  ...styleCenteredObject,
                  width: "100%",
                  height: "100%"
                }}
              >
                <Menu secondary style={{}}>
                  {this.props.listitem.map(item => {
                    return (
                      <Menu.Item
                        color="orange"
                        key={item.id}
                        name={item.name}
                        active={activeItem === item.name}
                        onClick={(e, { name }) => {
                          this.handleItemClick(name, item.navigateto);
                        }}
                        style={{
                          fontSize: 12,
                          color: "black",
                          fontWeight: "bold",
                          textTransform: "uppercase"
                        }}
                      />
                    );
                  })}
                </Menu>
              </div>
            </Grid.Column>
            <Grid.Column
              only="large screen"
              href={"tel:" + GetTextFromContent(this.contactInfo, "phoneSales")}
              width={3}
              verticalAlign="middle"
              style={{
                ...styleBasic,
                height: "100%"
              }}
            >
              <div
                style={{
                  ...styleBasic,
                  ...styleCenteredObject,
                  flexDirection: "row",
                  color: "black",
                  width: "100%",
                  height: "100%"
                }}
              >
                <Icon name="phone" />
                {GetTextFromContent(this.contactInfo, "phoneSales")}
              </div>
            </Grid.Column>
          </Grid>
        </GridForContent>
      </div>
    );
  }

  renderInMobile() {
    return (
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "4rem",
          padding: "0rem 1.5rem"
        }}
      >
        <div
          columns={"equal"}
          verticalAlign="middle"
          style={{
            ...styleBasic,
            ...styleCenteredObject,
            justifyContent: "space-between",
            width: "100%",
            height: "100%"
          }}
        >
          <div
            style={{
              ...styleBasic,
              ...styleCenteredObject,
              justifyContent: "flex-start",
              width: "100%",
              padding: "0.7rem 0rem",
              height: "100%"
            }}
          >
            <div
              onClick={() => {
                this.props.push("");
              }}
              style={{
                backgroundImage: "url(" + logo + ")",
                backgroundSize: "contain",
                backgroundPosition: "left center",
                width: "100%",
                height: "100%",
                backgroundRepeat: "no-repeat"
              }}
            />
          </div>
          <div
            style={{
              ...styleBasic,
              ...styleCenteredObject,
              justifyContent: "flex-end",
              width: "50%",
              height: "100%"
            }}
          >
            <Icon
              name={this.props.mobileMenuVisible ? "close" : "sidebar"}
              onClick={() => {
                this.props.onClickMobileMenu();
              }}
              size="large"
              style={{
                backgroundColor: "transparent",
                color: "black",
                zIndex: 9000,
                position: "relative"
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="nav">
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          {this.mainRender()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { pages } = data;
  return {
    pages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: to => {
      dispatch(push(to));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuComp)
);
