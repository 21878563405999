import React, { Component } from "react";
import ModalComponent from "../layouts/ModalComponent";
import { logosmall } from "../assets/Assets";
import { styleBasicCenteredObject } from "../style/inlineStyles";
import { Button } from "semantic-ui-react";
import Comptitle from "./Comptitle";

class ModalSendEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showModal !== this.state.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }
  }

  render() {
    return (
      <ModalComponent
        setBasic={true}
        width={"50%"}
        onOpen={() => {}}
        closeModal={() => this.props.closeModal()}
        showModal={this.state.showModal}
      >
        <div
          style={{
            ...styleBasicCenteredObject,
            padding: "2rem 0rem",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white"
          }}
        >
          <div
            style={{
              textAlign: "center",
              justifyContent: "center"
            }}
          >
            <Comptitle
              simple
              title="¡Bienvenido a CASAS ARA!"
              touppercase
              titlecolor="#c43a0e"
              titlesize="0.9rem"
              body="GRACIAS"
              bodycolor="black"
              bodysize="2rem"
            />
            <div
              style={{
                borderTop: "2px solid #c43a0e",
                width: "4rem",
                marginRight: "auto",
                marginLeft: "auto"
              }}
            />
          </div>
          <div style={{ height: "2rem" }} />
          <div
            style={{
              ...styleBasicCenteredObject,
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "80%"
            }}
          >
            <div
              style={{
                ...styleBasicCenteredObject,
                justifyContent: "flex-start",
                width: "100%",
                fontSize: "1.3rem",
                lineHeight: "2rem",
                bold: 500,
                textAlign: "left",
                color: "#131516"
              }}
            >
              {(this.props.message)? 
                 this.props.message
                : 
                "Sabemos lo importante que es este paso para ti, por ello en un lapso de 24 a 48 horas, uno de nuestros ejecutivos se pondrá en contacto contigo."
              }
            </div>
            <div style={{ height: "2rem" }} />

            <div
              style={{
                ...styleBasicCenteredObject,
                width: "100%"
              }}
            >
              <Button
                arial-label={"OK"}
                onClick={() => {
                  this.props.closeModal();
                }}
                style={{
                  ...styleBasicCenteredObject,
                  backgroundColor: "#C5390E",
                  color: "white",
                  borderRadius: 5,
                  width: "40%",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  height: "3rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center"
                }}
              >
                {"OK"}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    );
  }
}

export default ModalSendEmail;
