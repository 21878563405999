
import *  as types from '../types'

const stateDefault = {
    data: {},
    moreVisited: [],
    nearbyPosts: {}
};

export default function reducer(state = stateDefault, action ){
    const { type, payload } = action;

    switch(type){
        case types.SAVE_POST_DATA: 
            return { ...state, data: payload.data };
        case types.SAVE_NEARBY_POSTS: 
            return { ...state, nearbyPosts: payload.data };
        default:
            return state;
    }
}