export const SET_PAGES = "SET_PAGES";
export const SET_USER = "SET_USER";
export const SET_API = "SET_API";
export const SET_ID_CHECK = "SET_ID_CHECK";

export const SET_AMENITIES = "SET_AMENITIES";

export const SET_STATES = "SET_STATES";
export const SET_STATES_URLS = "SET_STATES_URLS";

export const SET_CONCEPTS = "SET_CONCEPTS";
export const SET_CONCEPTS_URLS = "SET_CONCEPTS_URLS";

export const SET_DEVELOPMENTS = "SET_DEVELOPMENTS";
export const SET_DEVELOPMENTS_URLS = "SET_DEVELOPMENTS_URLS";

export const SET_HOMEMODELS = "SET_HOMEMODELS";
export const SET_HOMEMODELS_URLS = "SET_HOMEMODELS_URLS";

export const SET_CREDITS = "SET_CREDITS";
export const SET_CREDITS_URLS = "SET_CREDITS_URLS";

export const SET_CREDIT_TYPES = "SET_CREDIT_TYPES";
export const SET_CREDIT_TYPES_URLS = "SET_CREDIT_TYPES_URLS";

export const SET_BLOGS = "SET_BLOGS";
export const SET_BLOG_FEATURED = "SET_BLOG_FEATURED";
export const SET_BLOG_URLS = "SET_BLOG_URLS";

export const SET_PROMOTIONS = "SET_PROMOTIONS";

export const  SAVE_BLOG_INFO = 'BLOG/BLOG/SAVE_BLOG_INFO';
export const  SAVE_POST_FEATURED = 'BLOG/BLOG/SAVE_POST_FEATURED';
export const  SAVE_POSTS_LIST = 'BLOG/BLOG/SAVE_POSTS_LIST';
export const  SAVE_LAST_POST = 'BLOG/BLOG/SAVE_LAST_POST';
export const  SAVE_BLOG_CATEGORIES = 'BLOG/BLOG/SAVE_BLOG_CATEGORIES';
export const  SAVE_BLOG_TAGS = 'BLOG/BLOG/SAVE_BLOG_TAGS'
export const  SAVE_POSTS_MORE_VISITED = 'BLOG/BLOG/SAVE_POSTS_MORE_VISITED';

export const  SAVE_POST_DATA = 'BLOG/POST/SAVE_POST_DATA';
export const  SAVE_NEARBY_POSTS= 'BLOG/POST/SAVE_NEARBY_POSTS';