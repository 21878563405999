import React, { Component } from "react";
import { Provider } from "react-redux";
import ReactBreakpoints from "react-breakpoints";
import {
  ConnectedRouter,
  routerMiddleware,
  routerReducer
} from "react-router-redux";
import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import { Responsive } from "semantic-ui-react";
import Navigator from "./Navigator";
import Reducers, { reducerBlog, reducerPost } from "./redux/reducers";
import createHistory from "history/createBrowserHistory";

let prevLocation = {};

function middlewareTopScrolle(location) {
  const pathChanged = prevLocation.pathname !== location.pathname;
  const hashChanged = prevLocation.hash !== location.hash;
  const searchChanged = prevLocation.search !== location.search;
  if (pathChanged || hashChanged || searchChanged) window.scrollTo(0, 0);
  prevLocation = location;
}

class App extends Component {
  breakPoints = {
    sm: Responsive.onlyMobile.minWidth,
    md: Responsive.onlyTablet.minWidth,
    lg: Responsive.onlyComputer.minWidth,
    xl: Responsive.onlyLargeScreen.minWidth,
    xxl: Responsive.onlyWidescreen.minWidth
  };

  constructor(props) {
    super(props);
    this.state = { canRender: true };
  }

  componentDidMount() {
    setTimeout(() => {
      this.zenDeskActive();
    }, 200);
  }

  semanticActive() {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      "//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css";
    document.body.appendChild(link);
  }

  zenDeskActive() {
    const script = document.createElement("script");
    script.id = "ze-snippet";
    script.src =
      "https://static.zdassets.com/ekr/snippet.js?key=2b48fec0-8d41-4817-be38-9d2d77834082";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    if (this.state.canRender === false) {
      return <div />;
    }
    this.history = createHistory();

    this.history.listen(middlewareTopScrolle);

    const middleware = [routerMiddleware(this.history), thunk];

    this.store = createStore(
      combineReducers({
        ...Reducers,
        blog: reducerBlog,
        post: reducerPost,
        router: routerReducer
      }),
      applyMiddleware(...middleware)
    );

    return (
      <Provider store={this.store}>
        <ConnectedRouter history={this.history}>
          <ReactBreakpoints
            defaultBreakpoint={this.breakPoints.mobile}
            breakpoints={this.breakPoints}
          >
            <Navigator store={this.store} />
          </ReactBreakpoints>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
