import React from "react";
import {
  Button,
  Divider,
  Grid,
  Icon,
  Responsive,
  Image
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";

/*----------------------Instrucciones de uso----------------------- */

/* 
  if(simple = true){Titulo y parrafo}
  if(titlesubtext = true){Titulo, subtitulo y parrafo}
  if(comptitle = true){Titulo, subtitulo, parrafo1 y parrafo2}
  if(richtext = true){Titulo, parrafo1 y parrafo2}
  if(simpleicon = true){Icono y Titulo}
  if(simplelink = true){Titulo, parrafo y link}
  if(logolinesimple = true){Linea, Logo, Titulo y parrafo}
  
  se puede agregar boton con la propiedad addButton
*/

class Comptitle extends React.Component {
  renderButton() {
    if (this.props.addButton) {
      return (
        <div style={{ paddingTop: "2rem" }}>
          <Button color="black" arial-label={"Ver Mas"}>
            {" "}
            VER MAS <Icon name="right arrow" />{" "}
          </Button>
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderIcon() {
    if (this.props.addIcon) {
      return <Icon name={this.props.iconname} size="large" />;
    }
  }

  renderRightComp() {
    if (this.props.simple) {
      return (
        <div style={{ ...this.props.style }}>
          <h1
            style={{
              fontSize: this.props.titlesize,
              color: this.props.titlecolor,
              letterSpacing: this.props.letterspacing,
              textTransform: this.props.touppercase ? "uppercase" : "",
              fontWeight: !this.props.titlebold ? "bold" : "normal",
              margin: this.props.marginTitle,
              width: this.props.titleWidth
            }}
          >
            {this.props.title}
          </h1>
          <p
            style={{
              fontSize: this.props.bodysize,
              textTransform: this.props.bodyuppercase,
              color: this.props.bodycolor,
              fontWeight: this.props.bold ? "bold" : "",
              margin: this.props.marginSubtitle,
              width: this.props.bodyWidth,
              lineHeight: this.props.bodyLineHeight || "1em"
            }}
          >
            {this.props.body}
          </p>
          {this.renderButton()}
        </div>
      );
    } else if (this.props.titlesubtext) {
      return (
        <div style={{ ...this.props.style }}>
          <h1
            style={{
              fontSize: this.props.titlesize,
              color: this.props.titlecolor,
              letterSpacing: this.props.letterspacing,
              textTransform: this.props.touppercase,
              fontWeight: this.props.titleBold ? "bold" : ""
            }}
          >
            {this.props.title}
          </h1>
          <h2
            style={{
              fontSize: this.props.subtitlesize,
              color: this.props.subtitlecolor,
              textTransform: this.props.transform,
              fontWeight: this.props.bold ? "bold" : "100",
              margin: "1rem 0rem"
            }}
          >
            {this.props.subtitle}
          </h2>
          <p
            style={{
              fontSize: this.props.bodysize,
              textTransform: this.props.bodyuppercase,
              letterSpacing: this.props.letterspacing,
              color: this.props.bodycolor,
              width: this.props.bodyWidth
            }}
          >
            {this.props.body}
          </p>
          {this.renderButton()}
        </div>
      );
    } else if (this.props.comptitle) {
      return (
        <div style={{ ...this.props.style }}>
          <h1
            style={{
              fontSize: this.props.titlesize,
              color: this.props.titlecolor,
              letterSpacing: this.props.letterspacing,
              textTransform: this.props.touppercase,
              fontWeight: this.props.titleBold ? "bold" : ""
            }}
          >
            {this.props.title}
          </h1>
          <h2
            style={{
              fontSize: this.props.subtitlesize,
              textTransform: this.props.transform
            }}
          >
            {this.props.subtitle}
          </h2>

          <p
            style={{
              fontSize: this.props.bodysize,
              textTransform: this.props.bodyuppercase,
              letterSpacing: this.props.letterspacing,
              color: this.props.bodycolor
            }}
          >
            {this.props.body}
          </p>
          <p
            style={{
              fontSize: this.props.bodysize,
              textTransform: this.props.bodyuppercase,
              letterSpacing: this.props.letterspacing,
              color: this.props.bodycolor
            }}
          >
            {this.props.body2}
          </p>
          {this.renderButton()}
        </div>
      );
    } else if (this.props.richtext) {
      return (
        <div style={{ ...this.props.style }}>
          <h1
            style={{
              fontSize: this.props.titlesize,
              color: this.props.titlecolor,
              letterSpacing: this.props.letterspacing,
              textTransform: this.props.touppercase ? "uppercase" : "",
              fontWeight: this.props.titleBold ? "bold" : ""
            }}
          >
            {this.props.title}
          </h1>

          <div
            dangerouslySetInnerHTML={{
              __html: this.props.bodyrich
            }}
          />
          <p
            style={{
              fontSize: this.props.bodysize,
              textTransform: this.props.bodyuppercase,
              letterSpacing: this.props.letterspacing,
              color: this.props.bodycolor
            }}
          >
            {this.props.body}
          </p>
          <p
            style={{
              fontSize: this.props.bodysize,
              textTransform: this.props.bodyuppercase,
              letterSpacing: this.props.letterspacing,
              color: this.props.bodycolor
            }}
          >
            {this.props.body2}
          </p>
          {this.renderButton()}
        </div>
      );
    } else if (this.props.simpleicon) {
      return (
        <div style={{ paddingBottom: "2rem" }}>
          <Grid style={{ paddingBottom: ".5rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={1}>
                <Icon name={this.props.iconname} size="large" />
              </Grid.Column>
              <Grid.Column width={3} style={{ paddingLeft: 2 }}>
                <h1
                  style={{
                    fontSize: this.props.titlesize,
                    color: this.props.titlecolor,
                    letterSpacing: this.props.letterspacing,
                    textTransform: this.props.touppercase,
                    fontWeight: this.props.titleBold ? "bold" : ""
                  }}
                >
                  {this.props.title}
                </h1>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <p
            style={{
              fontSize: this.props.bodysize,
              color: this.props.bodycolor
            }}
          >
            {this.props.body}
          </p>
          {this.renderButton()}
        </div>
      );
    } else if (this.props.logolinesimple) {
      return (
        <div style={{ ...this.props.style }}>
          <Grid columns={2}>
            <Grid.Column
              width={2}
              style={{
                padding: "0rem 0rem 0rem 1rem",
                margin: 0
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "90%",
                  paddingDown: 0,
                  marginBottom: 0,
                  borderBottom: "2px solid #ff1881"
                }}
              />
            </Grid.Column>
            <Grid.Column width={2} style={{ padding: 0 }}>
              <Image src={this.props.image} style={{}} size="tiny" />
            </Grid.Column>
          </Grid>
          <h1
            style={{
              fontSize: this.props.titlesize,
              color: this.props.titlecolor,
              letterSpacing: this.props.letterspacing,
              textTransform: this.props.touppercase,
              fontWeight: this.props.titleBold ? "bold" : ""
            }}
          >
            {this.props.title}
          </h1>

          <p
            style={{
              fontSize: this.props.bodysize,
              color: this.props.bodycolor
            }}
          >
            {this.props.body}
          </p>
        </div>
      );
    } else if (this.props.simplelink === 1 || 2) {
      if (this.props.simplelink === 1) {
        return (
          <div style={{ ...this.props.style }}>
            <h1
              style={{
                fontSize: this.props.titlesize,
                color: this.props.titlecolor,
                letterSpacing: this.props.letterspacing,
                textTransform: this.props.touppercase ? "uppercase" : "",
                fontWeight: this.props.titleBold ? "bold" : ""
              }}
            >
              {this.props.title}
            </h1>
            <Divider
              style={{ borderWidth: 1, width: "3rem", borderColor: "#ff1881" }}
            />
            <p
              style={{
                fontSize: this.props.bodysize,
                color: this.props.bodycolor
              }}
            >
              {this.props.body}
            </p>
            <br />
            <div style={{ textAlign: this.props.centered ? "center" : "" }}>
              <a
                onClick={() => {
                  this.props.push(this.props.pushto);
                }}
                href={this.props.focuson}
                style={{
                  textTransform: this.props.touppercase,
                  color: "black",
                  cursor: "pointer"
                }}
              >
                {this.props.link} <Icon name="right arrow" />
              </a>
            </div>
          </div>
        );
      } else if (this.props.simplelink === 2) {
        return (
          <div style={{ ...this.props.style }}>
            <div style={{ textAlign: this.props.centered ? "center" : "" }}>
              <a
                onClick={() => {
                  this.props.push(this.props.pushto);
                }}
                href={this.props.focuson}
                style={{
                  textTransform: this.props.touppercase,
                  color: "black",
                  cursor: "pointer"
                }}
              >
                {this.props.link} <Icon name="right arrow" />
              </a>
            </div>
          </div>
        );
      }
    } else {
      return <div />;
    }
  }

  renderInDesktop() {
    return this.renderRightComp();
  }

  renderInTablet() {
    return this.renderRightComp();
  }

  renderInMobile() {
    return this.renderRightComp();
  }

  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    push: to => {
      dispatch(push(to));
    }
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Comptitle)
);
