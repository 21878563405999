import { getCurrentLanguage } from "../MainInfoData";

export const finalIP = process.env.REACT_APP_API_HOST || "";

export const ImagesRes = [
  {
    dataName: "desktop",
    dataTitle: "Escritorio"
  },
  {
    dataName: "tablet",
    dataTitle: "Tableta"
  },
  {
    dataName: "mobile",
    dataTitle: "Móvil"
  }
];

export const GetPage = (pages, pageName) => {
  const currentPage = pages.find(x => {
    return x.name === pageName;
  });
  return currentPage;
};

export const GetSlider = (currentPage, sectionName, sliderName) => {
  const section = currentPage.sectionPages.find(x => {
    return x.name === sectionName;
  });

  const sliders = section.contentSections.filter(x => {
    return x.name.includes(sliderName) && x.isBase !== true;
  });

  sliders.sort(function(a, b) {
    return parseInt(a.value) - parseInt(b.value);
  });

  return sliders;
};

export const GetContent = (currentPage, sectionName, contentName) => {
  const section = currentPage.sectionPages.find(x => {
    return x.name === sectionName;
  });

  const content = section.contentSections.find(x => {
    return x.name.includes(contentName) && x.isBase !== true;
  });

  return content;
};

export const GetLinkFromContent = (content, linkName, language) => {
  let goTo = "";

  if (content !== undefined && content.dataContentLinks !== undefined) {
    const link = content.dataContentLinks.find(x => {
      return x.name === linkName;
    });

    let currentlanguage = "";
    if (language !== undefined) {
      currentlanguage = language;
    }

    if (link !== undefined) {
      goTo = link["link" + currentlanguage];
      if (goTo === undefined) {
        goTo = link.link;
      }
    }
  }
  return goTo;
};

export const GetTextFromContent = (content, textName, language) => {
  let textContent = "";
  if (content !== undefined && content.dataContentTexts !== undefined) {
    const text = content.dataContentTexts.find(x => {
      return x.name === textName;
    });

    let currentlanguage = "";
    if (language !== undefined) {
      currentlanguage = language;
    }

    if (text !== undefined) {
      textContent = text["content" + currentlanguage];
      if (textContent === undefined) {
        textContent = text.content;
      }
    }
  }
  return textContent;
};

export const GetRichTextFromContent = (content, textName, language) => {
  let textContent = "";
  if (content !== undefined && content.dataContentRichTexts !== undefined) {
    const text = content.dataContentRichTexts.find(x => {
      return x.name === textName;
    });

    let currentlanguage = "";
    if (language !== undefined) {
      currentlanguage = language;
    }

    if (text !== undefined) {
      textContent = text["content" + currentlanguage];
      if (textContent === undefined) {
        textContent = text.content;
      }
    }
  }
  return textContent;
};

export const GetImage = (object, dataName, language) => {
  let result = undefined;
  let currentlanguage = getCurrentLanguage(language);
  let b64 = object[dataName + currentlanguage];
  if (b64 === undefined || b64.preview === undefined) {
    b64 = object[dataName];
  }
  if (b64 !== undefined) {
    if (b64.preview === undefined) {
      let resultReturn = {};
      const arrayAvailableURLs = [];
      Object.keys(b64).forEach(key => {
        let currentImage = "";
        arrayAvailableURLs.push(b64[key]);
        if (b64[key].result !== undefined) {
          currentImage = b64[key];
        }
        resultReturn[key] = currentImage;
      });
      let allEmpty = false;
      let countEmpty = 0;
      for (let i = 0; i < ImagesRes.length; i++) {
        if (resultReturn[ImagesRes[i].dataName] === undefined) {
          countEmpty++;
          resultReturn[ImagesRes[i].dataName] = "";
        }
      }

      if (countEmpty >= ImagesRes.length) {
        allEmpty = true;
      }

      Object.keys(resultReturn).forEach(key => {
        if (resultReturn[key] === "") {
          const b64 = arrayAvailableURLs.find(tempB64 => {
            return tempB64.result !== undefined;
          });
          if (b64 !== undefined) {
            resultReturn[key] =  b64;
          }
        }
      });
      if (allEmpty === true) {
        resultReturn = "";
      }
      result = resultReturn;
    } else {
      result = finalIP + b64.preview;
    }
  }
  return result;
};


export const GetImageFromContent = (content, imageName, language) => {
  let result = undefined;
  if (content !== undefined && content.dataContentImages !== undefined) {
    const image = content.dataContentImages.find(x => {
      return x.name === imageName;
    });

    if (image !== undefined) {
      result = GetImage(image, "b64", language);
    }
  }
  return result;
};

export const GetMetatags = (pages, pageName) => {
  const currentPage = pages.find(x => {
    return x.name === pageName;
  });
  return currentPage.metaTags;
};

export const GetArrayALike = (currentPage, sectionName, likeName) => {
  const section = currentPage.sectionPages.find(x => {
    return x.name === sectionName;
  });

  const likeArray = section.contentSections.filter(x => {
    return x.name.includes(likeName) && x.isBase !== true;
  });

  for (let i = 0; i < likeArray.length; i++) {
    const currentSlider = likeArray[i];
    const stringValue = currentSlider.name.replace(likeName, "");
    const numberValue = parseInt(stringValue);
    likeArray[i].sortValue = numberValue;
  }

  likeArray.sort(function(a, b) {
    return parseInt(a.numberValue) - parseInt(b.numberValue);
  });

  return likeArray;
};

export const UpdateArrayImage = (currentPage, sectionName, currentArray) => {
  const section = currentPage.sectionPages.find(x => {
    return x.name === sectionName;
  });

  for (let i = 0; i < currentArray.length; i++) {
    const dataGet = section.contentSections.find(x => {
      return x.name === currentArray[i].name;
    });
    currentArray[i].dataContentImages = dataGet.dataContentImages;
  }

  return currentArray;
};
