import React from "react";
import moment from "moment";
import { withRouter } from "react-router";
import queryString from "query-string";

const getQueryVariable = variable => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return "";
};

const timeLimit = {
  type: "minutes",
  total: 10
};

class IDFromURLStart extends React.Component {
  constructor(props) {
    super(props);
    this.checkTimelimit = this.checkTimelimit.bind(this);
  }

  componentDidMount() {
    let returnIDCheck = {};
    let searchIDCheck = "";
    let searchIDCheckFrom = "";
    let currentImp = Number.MIN_SAFE_INTEGER;
    for (let i = 0; i < this.props.idsCheck.length; i++) {
      const currentIDCheck = this.props.idsCheck[i];
      if (currentIDCheck.imp > currentImp) {
        const tempIDCheck = getQueryVariable(currentIDCheck.key);
        let idToSet = currentIDCheck.key;
        let valueToSet = tempIDCheck;
        if (currentIDCheck.value !== undefined) {
          idToSet = "";
          valueToSet = "";
          let currentValueSet = "";
          let isFirst = true;
          for (let v = 0; v < currentIDCheck.value.length; v++) {
            const currentValue = currentIDCheck.value[v];
            const tempValueCheck = getQueryVariable(currentValue);
            if (tempValueCheck !== "") {
              if (isFirst === false) {
                currentValueSet += "&";
              }
              isFirst = false;
              currentValueSet += currentValue + "=" + tempValueCheck;
            }
          }

          if (currentValueSet !== "") {
            idToSet = tempIDCheck;
            valueToSet = currentValueSet;
          }
        }

        if (idToSet !== "" && valueToSet !== "") {
          searchIDCheckFrom = idToSet;
          searchIDCheck = valueToSet;
          currentImp = currentIDCheck.imp;
        }
      }
    }
    const savedIDCheck = sessionStorage.getItem("idcheck_data");
    const savedIDCheckFrom = sessionStorage.getItem("idcheck_from");
    const savedIDCheckDate = sessionStorage.getItem("idcheck_date");
    if (
      savedIDCheck !== undefined &&
      savedIDCheck !== null &&
      savedIDCheckFrom !== undefined &&
      savedIDCheckFrom !== null &&
      savedIDCheckDate !== undefined &&
      savedIDCheckDate !== null
    ) {
      if (
        (savedIDCheck !== searchIDCheck && searchIDCheck !== "") ||
        (savedIDCheckFrom !== searchIDCheckFrom && searchIDCheckFrom !== "")
      ) {
        sessionStorage.setItem("idcheck_data", searchIDCheck);
        sessionStorage.setItem("idcheck_from", searchIDCheckFrom);
        sessionStorage.setItem("idcheck_date", moment().toJSON());
        returnIDCheck = { key: searchIDCheckFrom, value: searchIDCheck };
      } else {
        let timeLimitUp = false;
        const dateToCheck = moment(savedIDCheckDate).add(
          timeLimit.total,
          timeLimit.type
        );
        if (dateToCheck.isBefore(moment())) {
          timeLimitUp = true;
        }
        if (timeLimitUp === true) {
          sessionStorage.removeItem("idcheck_data");
          sessionStorage.removeItem("idcheck_from");
          sessionStorage.removeItem("idcheck_date");
        } else {
          returnIDCheck = { key: savedIDCheckFrom, value: savedIDCheck };
        }
      }
    } else if (searchIDCheck !== "") {
      sessionStorage.setItem("idcheck_data", searchIDCheck);
      sessionStorage.setItem("idcheck_from", searchIDCheckFrom);
      sessionStorage.setItem("idcheck_date", moment().toJSON());
      returnIDCheck = { key: searchIDCheckFrom, value: searchIDCheck };
    }

    if (this.props.onGetIDCheck !== undefined) {
      this.props.onGetIDCheck(returnIDCheck);
    }
    
    const { history, removeKey } = this.props;

    if(removeKey && removeKey.length > 0){
       let queryStringParse = queryString.parse(history.location.search);
       
       
        removeKey.forEach((item) => delete queryStringParse[item]);

      history.replace({
        pathname: history.location.pathname,
        search: queryString.stringify(queryStringParse)
      });

    }

  }

  checkTimelimit(idCheckDate) {
    let timeLimitUp = false;
    const dateToCheck = moment(idCheckDate).add(
      timeLimit.total,
      timeLimit.type
    );
    if (dateToCheck.isBefore(moment())) {
      timeLimitUp = true;
    }
    return timeLimitUp;
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withRouter(IDFromURLStart);
