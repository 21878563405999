// Import package from node modules
import * as types from "../types";
import API, { mainIp } from "../../API/API";
import queryString from 'query-string';

import {
  GetContent,
  GetTextFromContent,
  GetImageFromContent
} from '../../scripts';


export const setPages = pages => {
  return {
    type: types.SET_PAGES,
    pages: pages
  };
};

export const setAPI = api => {
  return {
    type: types.SET_API,
    api: api
  };
};

export const setIDCheck = idcheck => {
  return {
    type: types.SET_ID_CHECK,
    idcheck: idcheck
  };
};

export const setAmenities = amenities => {
  return {
    type: types.SET_AMENITIES,
    amenities: amenities
  };
};

export const setStates = states => {
  return {
    type: types.SET_STATES,
    states: states
  };
};

export const setStatesURLs = statesURLs => {
  return {
    type: types.SET_STATES_URLS,
    statesURLs: statesURLs
  };
};

export const setConcepts = concepts => {
  return {
    type: types.SET_CONCEPTS,
    concepts: concepts
  };
};

export const setConceptsURLs = conceptsURLs => {
  return {
    type: types.SET_CONCEPTS_URLS,
    conceptsURLs: conceptsURLs
  };
};

export const setDevelopments = developments => {
  return {
    type: types.SET_DEVELOPMENTS,
    developments: developments
  };
};

export const setDevelopmentsURLs = developmentsURLs => {
  return {
    type: types.SET_DEVELOPMENTS_URLS,
    developmentsURLs: developmentsURLs
  };
};

export const setHomeModels = homemodels => {
  return {
    type: types.SET_HOMEMODELS,
    homemodels: homemodels
  };
};

export const setHomeModelsURLs = homemodelsURLs => {
  return {
    type: types.SET_HOMEMODELS_URLS,
    homemodelsURLs: homemodelsURLs
  };
};

export const setBlogsURLs = blogsURLs => {
  return {
    type: types.SET_BLOG_URLS,
    blogsURLs: blogsURLs
  };
};

export const setCredits = credits => {
  return {
    type: types.SET_CREDITS,
    credits: credits
  };
};

export const setCreditsURLs = creditsURLs => {
  return {
    type: types.SET_CREDITS_URLS,
    creditsURLs: creditsURLs
  };
};

export const setCreditTypes = creditTypes => {
  return {
    type: types.SET_CREDIT_TYPES,
    creditTypes: creditTypes
  };
};

export const setCreditTypesURLs = creditTypesURLs => {
  return {
    type: types.SET_CREDIT_TYPES_URLS,
    creditTypesURLs: creditTypesURLs
  };
};

export const setBlogs = blogs => {
  return {
    type: types.SET_BLOGS,
    blogs: blogs
  };
};

export const setBlogFeatured = blogsFeatured => {
  return {
    type: types.SET_BLOG_FEATURED,
    blogsFeatured: blogsFeatured
  };
};

export const setPromotions = promotions => {
  return {
    type: types.SET_PROMOTIONS,
    promotions: promotions
  };
};

export  function saveBlogInfo (data){
  return {
    type: types.SAVE_BLOG_INFO,
    payload: {
      data
    }
  };
};

export  function savePostFeatured (data){
  return {
    type: types.SAVE_POST_FEATURED,
    payload: {
      data
    }
  };
};

export  function savePostsList(data){
  return {
    type: types.SAVE_POSTS_LIST,
    payload: {
      data
    }
  };
};

export  function saveLastPost(data){
  return {
    type: types.SAVE_LAST_POST,
    payload: {
      data
    }
  };
};

export  function saveBlogCategories(data){
  return {
    type: types.SAVE_BLOG_CATEGORIES,
    payload: {
      data
    }
  };
};

export  function saveBlogTags(data){
  return {
    type: types.SAVE_BLOG_TAGS,
    payload: {
      data
    }
  };
};

export  function savePostsMoreVisited(data){
  return {
    type: types.SAVE_POSTS_MORE_VISITED,
    payload: {
      data
    }
  };
};

export  function savePostData(data){
  return {
    type: types.SAVE_POST_DATA,
    payload: {
      data
    }
  };
};

export  function saveNearbyPosts(data){
  return {
    type: types.SAVE_NEARBY_POSTS,
    payload: {
      data
    }
  };
};


export  function requestGetBlogInfo (pageId){
  
  return  async (dispatch) => {
    
    const api = new API();

    
    const blogInfoPromisse = api.getContentOf(pageId);
    const imageInfoPromisse = api.getImageOf(pageId);

      return Promise.all([blogInfoPromisse, imageInfoPromisse]).then((data) => {
          let contentInfo = GetContent(data[0][0], "main", "blogInfo");
          let imageInfo = GetContent(data[1][0], "main", "blogInfo");
          let linkInfo = GetContent(data[0][0], "main", "links");

          dispatch(saveBlogInfo({contentInfo, imageInfo, linkInfo}));   
      });  
    };
};

export  function requestGetPostFeatured (){
  
  return  async (dispatch, getState) => {
    const contentInfo = getState().blog.blogInfo.contentInfo;
    const api = new API();
    
    const featuredPosts = await api.getFeaturedBlogs();
    
    const formattedFeaturedPost = featuredPosts.map((post) => {

      let url = post.id;
      
      if (post.url) {
        url = post.url;
      }

      return {
        description: post.title,
        date: post.publishDate,
        button: {
          label: 'Ir a Entrada',
          url: '/articulos-y-noticias/' + url,
        },
        image: post.mainImg,
        id: post.id,
        title: GetTextFromContent(contentInfo, "topText"),
      };

    });
    

    if(!formattedFeaturedPost.length){
      formattedFeaturedPost.push({
        description: GetTextFromContent(contentInfo, "bottomText"),
        button: {
          label: 'Ir a Entrada',
          url: '/articulos-y-noticias',
        },
        image: GetImageFromContent(getState().blog.blogInfo.imageInfo, "img"),
        id: contentInfo.id,
        title: GetTextFromContent(contentInfo, "topText"),
      });
    }

    dispatch(savePostFeatured(formattedFeaturedPost));

  };
};

export  function requestGetPostsList (queryStringCurrent){

  const parsedQueryString = queryString.parse(queryStringCurrent),
        sizeQueryString = Object.keys(parsedQueryString).length,
        limit = 12;
  
  return  async (dispatch) => {
    
    const api = new API();
    let postList = null;
  
    if( sizeQueryString && (parsedQueryString.categories || parsedQueryString.tags)){

      postList = await api.getPosts(parsedQueryString.page || 1 , limit, parsedQueryString.categories,  parsedQueryString.tags);

    }else if(sizeQueryString && parsedQueryString.keyword){
      postList = await api.getSearchPosts(parsedQueryString.keyword, parsedQueryString.page || 1 , limit);
    }else{
      
      postList = await api.getBlogs("Casas Ara", parsedQueryString.page || 1, limit);
    }
    
    dispatch(savePostsList({
      page: postList.page,
      pages: postList.pages,
      posts: postList.products,
      totalPosts: postList.totalProducts
    }));
  };
};

export  function requestGetLastPost (){
  
  return  async (dispatch) => {
    
    const api = new API();
    
    const post = await api.getBlogs("Casas Ara", 1, 1);
    
    dispatch(saveLastPost(post.products[0]));

  };
};

export  function requestGetBlogCategories (limit, itemAll = false){
  
  return  async (dispatch) => {
    
    const api = new API();
    
    let blogCategories = await api.getBlogCategories(limit);
    
    if(itemAll){
        const itemAll = { id: Math.random().toString(36).substring(7), name: 'Todas' };
        blogCategories.unshift(itemAll);
    }

    dispatch(saveBlogCategories(blogCategories));

    return Promise.resolve();
  };
};

export  function requestGetBlogTags (limit, itemAll = false){
  
  return  async (dispatch) => {
    
    const api = new API();
    
    const blogTags = await api.getBlogTags(limit);
    
    if(itemAll){
      const itemAll = { id: Math.random().toString(36).substring(7), name: 'Todas' }
      blogTags.unshift(itemAll);
    }

    dispatch(saveBlogTags(blogTags));
    
    return Promise.resolve();
  };
};

export  function requestGetPostsMoreVisited (limit){
  
  return  async (dispatch) => {
    
    const api = new API();
    
    const posts = await api.getPostsMoreVisited(limit);
    
    dispatch(savePostsMoreVisited(posts));

  };
};

export  function requestGetPost (pathTo){
  
  return  async (dispatch, getState) => {
    
    const api = new API(),
          blogsUrls = getState().data.blogsURLs;
    let post = null;

    const searchUrl = blogsUrls.find( url =>  ("/articulos-y-noticias/" + url.url.trim())=== pathTo.trim() );

    if (searchUrl) {
      post = await api.getBlog(searchUrl.id);
      
      if(post){
        dispatch(savePostData(post));
        return Promise.resolve(post);
      }
    }
    dispatch(savePostData({}));
    return Promise.resolve(post);

  };
};

export  function requestNearbyPots (){
  
  return  async (dispatch, getState) => {
    
    const api = new API(),
          currentPostId = getState().post.data.id;
    let post = null;

    post = await api.getNearbyPosts(currentPostId);
    
    dispatch(saveNearbyPosts(post));

  };
};


export  function requestSendJob (data){
  
  return  async (dispatch) => {
    
    const api = new API();
    
    const response = await api.sendJob(data);
    
    return Promise.resolve(response);

  };
};
